import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Divider } from 'primereact/divider';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';
import { ToggleButton } from 'primereact/togglebutton';
import useIsMobile from '../../utils/useIsMobile';

const ConnectionSettings = ({backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, openDialogComponent = () => {}, setIsBlur = () => {}, refresh = () => {}}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const [redeemReward, setRedeemReward] = useState({});
    const [loading, setLoading] = useState(false);
    const [showSpotifyDeleteConfirm, setShowSpotifyDeleteConfirm] = useState(false);
    const [connectionTestText, setConnectionTestText] = useState('Test not run yet.');
    const isMobile = useIsMobile();
    const styles = {
        container: {
            backgroundColor,
            width: '100%',
            height: '70vh',
            paddingLeft: '32px',
            paddingRight: '32px',
            paddingTop: '32px'
        },
        title: {
            color: 'var(--complementary-color)'
        },
        text: {
            color: 'var(--complementary-color)'
        },
        noMarginOrPadding: {
            margin: '0px',
            padding: '0px'
        },
        inputText: {
            backgroundColor: 'var(--background-color-darker)',
            color: 'var(--complementary-color)',
            width: '100%',
            marginTop: '16px'
        },
        h3: {
            fontSize: '1.25em',
            fontWeight: 'bold',
            margin: '0',
            padding: '0'
        },
        label: {
            color: 'var(--complementary-color)'
        },
        inputNumber: {
            width: '100%',
            backgroundColor: 'var(--background-color-darker)'
        },
        connectionTest: {
            backgroundColor: 'var(--background-color-darker)', 
            padding: '8px',
            paddingLeft: '16px',
            paddingRight: '16px',
            border: '1px solid var(--background-color-lighter)', 
            borderRadius: '6px'
        },
        toggleButton: {
            width: '100%'
        }
    };
    const hasRewardId = () => {
        return userSettings.spotify.rewards.songRequest.id !== '';
    };
    const getRewardDetails = async (id = '', type = 'redeem') => {
        try {
            if (id === '') return;
            setLoading(true);
            const response = await fetch(`${apiUrl}/user/customreward?token=${authCtx.token}&id=${id}`);
            if (response.ok) {
                const res = await response.json();
                switch (type) {
                    case 'redeem':
                        setRedeemReward(res);       
                        break;
                }
            } else {
                update({spotify: {...userSettings.spotify, rewards: {...userSettings.spotify.rewards, songRequest: {...userSettings.spotify.rewards.songRequest, id: ''}}}});
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const deleteSpotifyData = async () => {
        try {
            const response = await fetch(`${apiUrl}/spotify/deletedata?token=${authCtx.token}`);
            if (response.ok) {
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (hasRewardId()) {
            getRewardDetails(userSettings.spotify.rewards.songRequest.id, 'redeem');
        }
    }, []);

    const CommandAndChatSection = () => {
        const [requestCommand, setRequestCommand] = useState(userSettings.spotify.commands.songRequest);
        const [allowChatRequests, setAllowChatRequests] = useState(userSettings.spotify.commands.songRequest !== '');
        const [allowConversationRequests, setAllowConversationRequests] = useState(userSettings.spotify.allowConversationRequests || false);

        const updateRequestCommand = () => {
            update({spotify: {...userSettings.spotify, commands: {...userSettings.spotify.commands, songRequest: requestCommand}}});
        };

        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <InputSwitch id="chatRequestsSwitch" checked={allowChatRequests} onChange={(e) => {
                                        if (e.value) {
                                            setRequestCommand('spotify');
                                            setAllowChatRequests(true);
                                            update({spotify: {...userSettings.spotify, commands: {...userSettings.spotify.commands, songRequest: 'spotify'}}});
                                        } else {
                                            setRequestCommand('');
                                            setAllowChatRequests(false);
                                            update({spotify: {...userSettings.spotify, commands: {...userSettings.spotify.commands, songRequest: ''}}});
                                        }
                                    }} style={{top: '8px'}} />
                                <label htmlFor="chatRequestsSwitch" style={{...styles.text, ...styles.h3, ...styles.noMarginOrPadding, marginLeft: '16px'}}>Allow requests via chat command</label>
                            </Grid>
                            {allowChatRequests && (
                                <Grid item xs={12}>
                                    <InputText id="requestCommandInput" style={styles.inputText} value={`${userSettings.commandDelimiter}${requestCommand}`}
                                        onChange={(e) => setRequestCommand(e.target.value.substring(1, e.target.value.length))} onBlur={updateRequestCommand} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: '16px'}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <InputSwitch id="conversationRequestsSwitch" style={{top: '8px'}} checked={allowConversationRequests} onChange={(e) => {
                                    if (e.value) {
                                        setAllowConversationRequests(true);
                                        update({spotify: {...userSettings.spotify, allowConversationRequests: true}});
                                    } else {
                                        setAllowConversationRequests(false);
                                        update({spotify: {...userSettings.spotify, allowConversationRequests: false}});
                                    }
                                }} />
                                <label htmlFor="conversationRequestsSwitch" style={{...styles.text, ...styles.noMarginOrPadding, ...styles.h3, marginLeft: '16px'}}>Allow requests in conversation</label>
                            </Grid>
                            <Grid item xs={12}>
                                <p id="conversationHelpText" style={{...styles.text, ...styles.noMarginOrPadding, marginTop: '16px'}}>
                                    Allows viewers to request songs by asking, e.g. "@RatbotTTV, please play a good Metallica song"
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );        
    };
    const ChannelPointsRewardSection = () => {
        const [newRewardTitle, setNewRewardTitle] = useState('Spotify Song Request');
        const [newRewardCost, setNewRewardCost] = useState(1000);
        const [newRewardPrompt, setNewRewardPrompt] = useState('Adds a song to the Spotify queue. Spotify link or search text can be used.');
        const [errorText, setErrorText] = useState('');
        const [requiresPermission, setRequiresPermission] = useState(false);

        const createNewReward = async () => {
            try {
                setErrorText('Creating reward...');
                const url = `${apiUrl}/user/createcustomreward?token=${authCtx.token}&type=spotify`;
                const body = {
                    title: newRewardTitle,
                    cost: newRewardCost,
                    prompt: newRewardPrompt
                };
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });
                if (response.ok) {
                    window.location.reload();
                } else {
                    const data = await response.json();
                    let extraInfo = ' - ';
                    switch (response.status) {
                        case 400:
                            extraInfo += 'Please check: Title is between 1-45 characters and must be unique among your rewards, Cost is minimum 1 point, and Prompt is less than 200 characters.';
                            break;
                        case 401:
                            extraInfo += 'Ratbot does not have permission to create custom reward for you.';
                            setRequiresPermission(true);
                            break;
                        case 403:
                            extraInfo += 'Twitch requires you to be a partner or affiliate to create custom app rewards.';
                            break;
                        case 500:
                            extraInfo += 'There was an error from Twitch processing your request. Please try again later!';
                            break;
                        
                        default:
                            extraInfo += 'An unknown error occurred!';
                            break;
                    }
                    setErrorText(`${data.error}${extraInfo}`);
                }
            } catch (error) {
                console.log(error);
                setErrorText(error.message);
            }
        };

        return (
            <Grid item xs={12}>
                {(hasRewardId() && Object.keys(redeemReward).length > 0) ? (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h2 style={styles.title}>{`Request songs via channel points reward`}</h2>
                        </Grid>
                        <Grid item xs={6}>
                            <Button label='Manage Reward' severity='info' style={{color: 'black', width: '100%', marginTop: '12px'}} size='small'
                                onClick={() => openDialogComponent('updatereward', redeemReward)} />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h3 style={styles.title}>You currently do not have a channel point reward set up.</h3>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label style={styles.label} htmlFor="title">Title</label>
                            <InputText maxLength={45} style={{...styles.inputText, marginTop: '0px'}} id="title" name="title" value={newRewardTitle} 
                                onChange={(e) => setNewRewardTitle(e.target.value)} className="p-inputtext-sm" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label style={styles.label} htmlFor="cost">Cost</label>
                            <InputNumber style={styles.inputNumber} id="cost" name="cost" value={newRewardCost} min={1}
                                onChange={(e) => setNewRewardCost(e.value)} className="p-inputtext-sm" />
                        </Grid>
                        <Grid item xs={12}>
                            <label style={styles.label} htmlFor="prompt">Prompt</label>
                            <InputText maxLength={200} style={{...styles.inputText, marginTop: '0px'}} id="prompt" name="prompt" className="p-inputtext-sm"
                                value={newRewardPrompt} onChange={(e) => setNewRewardPrompt(e.target.value)} />
                        </Grid>
                        {errorText !== '' && (
                            <Grid item xs={12}>
                                <h4 style={{...styles.title, ...styles.noMarginOrPadding}}>{errorText}</h4>
                            </Grid>
                        )}
                        {requiresPermission && (
                            <Grid item xs={12}>
                                <Button size='small' severity='info' style={{color: 'black'}} label='Grant Permission' onClick={() => openDialogComponent('addtwitchpermission', {scope: ['channel:manage:redemptions']})} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button size='small' severity='success' style={{color: 'black'}} label='Create Reward' onClick={createNewReward} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    };
    const ConnectionSection = () => {
        const testConnection = async () => {
            try {
                setConnectionTestText('Testing...');
                const response = await fetch(`${apiUrl}/spotify/getgenreseeds?refreshtoken=${userSettings.spotify.refreshToken}`);
                if (response.ok) {
                    setConnectionTestText('Test was successful!');
                } else {
                    const data = await response.json();
                    setConnectionTestText(`Test was unsuccessful: ${data.error}`);
                }
            } catch (error) {
                console.log(error);
                setConnectionTestText(`Test was unsuccessful: ${error.message}`);
            }
        };

        return (
            <Grid container>
                <Grid item xs={12}>
                    <h1 style={{...styles.title, ...styles.noMarginOrPadding}}>Connection</h1>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <h3 style={styles.title}>Ratbot has a token for connecting to Spotify</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button style={{color: 'black', width: '100%'}} size='small' label='Test Connection' severity='info' onClick={testConnection} />
                                </Grid>
                                <Grid item xs={12}>
                                    <h3 style={{...styles.text, ...styles.noMarginOrPadding, ...styles.connectionTest}}>{connectionTestText}</h3>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isMobile && 
                        <Grid item xs={1}>
                            <Divider layout='vertical' />
                        </Grid>
                    }
                    <Grid item xs={12} sm={5}>
                        <Grid item xs={12}>
                            <h3 style={styles.title}>Having issues? Use below to clear your Spotify data and restart the setup process.</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <Button size='small' severity='danger' style={{color: 'black', width: '100%'}} label='Restart Spotify Setup' 
                                onClick={() => {
                                    setShowSpotifyDeleteConfirm(true);
                                    setIsBlur(true);
                                }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={styles.noMarginOrPadding} />
                    </Grid>
                    <Grid item xs={12}>
                        <h2 style={styles.title}>Song requests enable</h2>
                        <p style={styles.text}>Use the toggle button below to quickly enable/disable all song requests. If disabled, Ratbot will ignore song requests from all sources.</p>
                        <ToggleButton onLabel='Requests are paused' offLabel='Requests are enabled' onIcon='pi pi-play' offIcon='pi pi-pause' 
                            className={userSettings.spotify.requestsPaused ? 'requests-pause-toggle-on' : 'requests-pause-toggle-off'} style={styles.toggleButton}
                            checked={userSettings.spotify.requestsPaused} onChange={(e) => update({spotify: {...userSettings.spotify, requestsPaused: e.value}})} />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Box sx={styles.container}>
            <ConfirmDialog visible={showSpotifyDeleteConfirm} onHide={() => { setShowSpotifyDeleteConfirm(false); setIsBlur(false); }} 
                message="Are you sure you want to delete your Spotify data?" position='center' draggable={false}
                header="Spotify Data Deletion" icon="pi pi-times" accept={deleteSpotifyData} reject={() => { setShowSpotifyDeleteConfirm(false); setIsBlur(false); }} />
            <Grid container spacing={4}>
                <Grid item xs={5}>
                    <ConnectionSection />
                </Grid>
                <Grid item xs={1} sx={{height: '67.5vh'}}>
                    <Divider layout='vertical' style={{height: '100%'}} />
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <h1 style={{...styles.title, ...styles.noMarginOrPadding}}>Song Requests</h1>
                        </Grid>
                        <Grid xs={12}>
                            <Divider />
                        </Grid>
                        <Grid xs={12}>
                            {loading ? (<h2 style={styles.title}>Fetching reward data...</h2>) : (<ChannelPointsRewardSection />)}
                        </Grid>
                        <Grid xs={12}>
                            <Divider />
                        </Grid>
                        <Grid xs={12}>
                            <CommandAndChatSection />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ConnectionSettings;