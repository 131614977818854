import { Box, Grid } from '@mui/material';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import React, { useEffect, useState } from 'react';

const Filters = ({backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, openDialogComponent = () => {}, setIsBlur = () => {}, refresh = () => {}}) => {
    const [selectedGroup, setSelectedGroup] = useState({label: 'Default'});
    const [permissionSet, setPermissionSet] = useState(userSettings.spotify.groupPermissions[userSettings.spotify.groupPermissions.findIndex(group => group.groupName === 'Default')]);
    const styles = {
        container: {
            backgroundColor,
            width: '100%',
            height: '70vh',
            paddingLeft: '32px',
            paddingRight: '32px',
            paddingTop: '32px'
        },
        noMarginOrPadding: {
            margin: '0px',
            padding: '0px'
        },
        title: {
            color: 'var(--complementary-color)'
        },
        blockButton: {
            width: '100%',
            color: 'black'
        },
        inputText: {
            backgroundColor: 'var(--background-color-darker)',
            color: 'var(--complementary-color)',
            width: '100%'
        }
    };

    useEffect(() => {
        const _permissionSet = {...[...userSettings.spotify.groupPermissions].find(group => group.groupName === selectedGroup.label)};
        setPermissionSet(_permissionSet);
    }, [selectedGroup]);
    useEffect(() => {
        const _groupPermissions = [...userSettings.spotify.groupPermissions];
        const selectedIndex = _groupPermissions.findIndex(group => group.groupName === selectedGroup.label);
        _groupPermissions[selectedIndex] = {...permissionSet};
        update({spotify: {...userSettings.spotify, groupPermissions: _groupPermissions}});
    }, [permissionSet]);

    const updatePermissionValue = (key, value) => {
        setPermissionSet((prev) => ({...prev, [key]: value}));
    };

    const BlockOptionsSection = () => {
        const [minimumTrackLength, setMinimumTrackLength] = useState(userSettings.spotify.minLength);
        const [maximumTrackLength, setMaximumTrackLength] = useState(userSettings.spotify.maxLength);
        const [minimumPopularity, setMinimumPopularity] = useState(userSettings.spotify.minPopularity);
        const [allowExplicit, setAllowExplicity] = useState(userSettings.spotify.allowExplicit);

        const convertToTimeFormat = (seconds) => {
            if (typeof seconds !== 'number' || seconds < 0 || !Number.isInteger(seconds)) {
                throw new Error('Input must be a non-negative integer');
            }
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = remainingSeconds.toString().padStart(2, '0');
            if (hours > 0) {
                return `${hours}:${paddedMinutes}:${paddedSeconds}`;
            } else {
                return `${paddedMinutes}:${paddedSeconds}`;
            }
        };

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1 style={{...styles.noMarginOrPadding, ...styles.title}}>Block Options</h1>
                </Grid>
                <Grid item xs={12}>
                    <h3 style={{...styles.noMarginOrPadding, ...styles.title}}>Manage which tracks and artists should be blocked from being requested by viewers.</h3>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button label='Blocked Tracks' severity='info' size='small' style={styles.blockButton} onClick={() => openDialogComponent('spotifyBlockedTracks')} />
                        </Grid>
                        <Grid item xs={6}>
                            <Button label='Blocked Artists' severity='info' size='small' style={styles.blockButton} onClick={() => openDialogComponent('spotifyBlockedArtists')} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <h2 style={{...styles.noMarginOrPadding, ...styles.title}}>Set restrictions on which tracks can be requested.</h2>
                </Grid>
                <Grid item xs={12} sx={{marginTop: '16px'}}>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <h3 style={{...styles.noMarginOrPadding, ...styles.title}}>Minimum track length</h3>
                        </Grid>
                        <Grid item xs={6}>
                            <h3 style={{...styles.noMarginOrPadding, ...styles.title}}>Maximum track length</h3>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <InputText style={styles.inputText} value={convertToTimeFormat(minimumTrackLength)} />
                            <Slider style={{width: '100%'}} step={30} min={0} max={3600} value={minimumTrackLength} onChange={(e) => {
                                    if (e.value < maximumTrackLength) {
                                        setMinimumTrackLength(e.value);
                                    } else {
                                        setMinimumTrackLength(maximumTrackLength);
                                    }
                                }}
                                onSlideEnd={(e) => {
                                    if (e.value < maximumTrackLength) {
                                        update({spotify: {...userSettings.spotify, minLength: e.value}});
                                    } else {
                                        update({spotify: {...userSettings.spotify, minLength: maximumTrackLength}});
                                    }
                                }} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputText style={styles.inputText} value={convertToTimeFormat(maximumTrackLength)} />
                            <Slider style={{width: '100%'}} step={30} min={0} max={3600} value={maximumTrackLength} onChange={(e) => {
                                    if (e.value > minimumTrackLength) {
                                        setMaximumTrackLength(e.value);
                                    } else {
                                        setMaximumTrackLength(minimumTrackLength);
                                    }
                                }}
                                onSlideEnd={(e) => {
                                    if (e.value > minimumTrackLength) {
                                        update({spotify: {...userSettings.spotify, maxLength: e.value}});
                                    } else {
                                        update({spotify: {...userSettings.spotify, maxLength: minimumTrackLength}});
                                    }
                                }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{marginTop: '32px'}}>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <h3 style={{...styles.noMarginOrPadding, ...styles.title}}>Minimum track popularity</h3>
                        </Grid>
                        <Grid item xs={6}>
                            <h3 style={{...styles.noMarginOrPadding, ...styles.title}}>Allow explicit lyrics</h3>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <InputText style={styles.inputText} value={`${minimumPopularity}%`} />
                            <Slider style={{width: '100%'}} step={1} min={0} max={100} value={minimumPopularity} onChange={(e) => setMinimumPopularity(e.value)}
                                onSlideEnd={(e) => update({spotify: {...userSettings.spotify, minPopularity: e.value}})} />
                        </Grid>
                        <Grid item xs={6} sx={{marginTop: '4px'}}>
                            <InputSwitch id="allowExplicitSwitch" style={{top: '8px'}} checked={allowExplicit} onChange={(e) => {
                                if (e.value) {
                                    setAllowExplicity(true);
                                    update({spotify: {...userSettings.spotify, allowExplicit: true}});
                                } else {
                                    setAllowExplicity(false);
                                    update({spotify: {...userSettings.spotify, allowExplicit: false}});
                                }
                            }} />
                            <label htmlFor="allowExplicitSwitch" style={{...styles.text, ...styles.noMarginOrPadding, ...styles.h3, marginLeft: '16px'}}>
                                Allow tracks containing explicit lyrics
                            </label>
                        </Grid>
                    </Grid>
                </Grid>
                {/*<Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h3 style={{...styles.noMarginOrPadding, ...styles.title}}>Blocked genres</h3>
                                    <p style={{...styles.title, ...styles.noMarginOrPadding, marginTop: '8px'}}>Set up which genres of music should be blocked from being redeemed. By default, all genres are allowed.</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button style={{color: 'black', width: '100%'}} size='small' severity='info' label='Manage Genres' onClick={() => openDialogComponent('spotifyBlockedGenres')} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>*/}
            </Grid>
        );
    };
    const PermissionsSection = ({permissionSet = {}, updatePermissionValue = (key, value) => {}}) => {
        const permissionGroups = ['Default', 'Subscribers', 'VIPs', 'Moderators'];
        const fulfillOptions = [
            {label: 'Immediately', value: 'onRequest'},
            {label: 'When song starts playing', value: 'onPlay'},
            {label: 'Never', value: 'never'}
        ];
        const refundOptions = [
            {label: 'Song could not be added', value: 'onAddFail'},
            {label: 'On error (no refund for blocked tracks)', value: 'onError'},
            {label: 'Always', value: 'always'},
            {label: 'Never', value: 'never'}
        ];
        // Local state for controlled inputs
        const [requestsPerStream, setRequestsPerStream] = useState(permissionSet.requestsPerStream || 0);
        const [freeRequestsPerStream, setFreeRequestsPerStream] = useState(permissionSet.freeRequestsPerStream || 0);
        const [requestsInQueue, setRequestsInQueue] = useState(permissionSet.requestsInQueue || 0);

        const handleBlur = (key, value) => {
            // If the input is empty, set it to 0; otherwise, parse it as an integer
            updatePermissionValue(key, value === '' ? 0 : parseInt(value, 10));
        };

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h1 style={{...styles.noMarginOrPadding, ...styles.title}}>{`Permissions for group: ${selectedGroup.label}`}</h1>
                </Grid>
                <Grid item xs={12}>
                    <Dropdown options={permissionGroups.map((v, i) => ({label: v}))} optionLabel='label' value={selectedGroup} onChange={(e) => setSelectedGroup(e.value)}
                        highlightOnSelect={false} style={{width: '100%'}} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <InputSwitch id="allowRequestsGroup" style={{top: '8px'}} checked={permissionSet.isAllowedRequests} 
                                onChange={(e) => updatePermissionValue('isAllowedRequests', e.value)} />
                            <label htmlFor="allowRequestsGroup" style={{...styles.text, ...styles.noMarginOrPadding, ...styles.h3, marginLeft: '16px'}}>
                                Song requests enabled
                            </label>
                        </Grid>
                        <Grid item xs={1}>
                            <Divider layout='vertical' />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <p>Requests per stream</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputText
                                        value={requestsPerStream}
                                        onChange={(e) => setRequestsPerStream(e.target.value.replace(/\D/g, ''))}
                                        onBlur={() => handleBlur('requestsPerStream', requestsPerStream)}
                                        style={{ width: '100%', backgroundColor: 'var(--background-color-darker)' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <p>Free requests per stream</p>
                                </Grid>
                                <Grid item xs={6} sx={{width: '100%'}}>
                                    <InputText
                                        value={freeRequestsPerStream}
                                        onChange={(e) => setFreeRequestsPerStream(e.target.value.replace(/\D/g, ''))}
                                        onBlur={() => handleBlur('freeRequestsPerStream', freeRequestsPerStream)}
                                        style={{ width: '100%', backgroundColor: 'var(--background-color-darker)' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Divider layout='vertical' />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <p>Pending songs in queue</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputText
                                        value={requestsInQueue}
                                        onChange={(e) => setRequestsInQueue(e.target.value.replace(/\D/g, ''))}
                                        onBlur={() => handleBlur('requestsInQueue', requestsInQueue)}
                                        style={{ width: '100%', backgroundColor: 'var(--background-color-darker)' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <h2 style={{...styles.noMarginOrPadding, ...styles.title}}>Channel Points</h2>
                </Grid>
                <Grid item xs={12} sx={{marginTop: '16px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h3 style={{...styles.noMarginOrPadding, ...styles.title}}>Fulfill reward</h3>
                        </Grid>
                        <Grid item xs={6}>
                            <h3 style={{...styles.noMarginOrPadding, ...styles.title}}>Refund points</h3>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Dropdown style={{width: '100%'}} options={fulfillOptions} optionLabel='label' value={permissionSet.fulfillSetting} 
                                onChange={(e) => updatePermissionValue('fulfillSetting', e.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <Dropdown style={{width: '100%'}} options={refundOptions} optionLabel='label' value={permissionSet.refundSetting} 
                                onChange={(e) => updatePermissionValue('refundSetting', e.value)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Box sx={styles.container}>
            <Grid container spacing={4}>
                <Grid item xs={5}>
                    <BlockOptionsSection />
                </Grid>
                <Grid item xs={1} sx={{height: '67.5vh'}}>
                    <Divider layout='vertical' />
                </Grid>
                <Grid item xs={6}>
                    <PermissionsSection permissionSet={permissionSet} updatePermissionValue={updatePermissionValue} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Filters;