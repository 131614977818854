import React, { useState, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Grid } from '@mui/material';
import { Toast } from 'primereact/toast'; // Import the Toast component
import './StringBuilder.css';

const StringBuilder = ({backgroundColor = '#5D3B9E', userSettings = {}}) => {
  const [variableType, setVariableType] = useState(null);
  const [variable, setVariable] = useState(null);
  const [method, setMethod] = useState(null);
  const [argument, setArgument] = useState('');
  const [username, setUsername] = useState(null);
  const [expression, setExpression] = useState('');
  const [showNestedBuilder, setShowNestedBuilder] = useState(false);
  const toast = useRef(null); // Toast reference
  const [usernameOptions, setUsernameOptions] = useState([]);
  const [explanation, setExplanation] = useState('');
  const argumentRef = useRef(null);
  const [argumentSelectionStart, setArgumentSelectionStart] = useState(0);
  const [argumentSelectionEnd, setArgumentSelectionEnd] = useState(0);
  const colorScheme = {
    background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.75)`,
    background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.5)`,
    background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.25)`
  };
  const styles = {
    dropdown: {
      backgroundColor: colorScheme.background75
    },
    textfield: {
      backgroundColor: colorScheme.background75
    }
  };
  const getVariablesOfType = (type = 'number') => {
    const returnArr = [];
    for (let varName of Object.keys(userSettings.customVariables)) {
      if (userSettings.customVariables[varName].datatype === type) {
        const variable = {
          label: userSettings.customVariables[varName].title,
          value: varName,
          unique: userSettings.customVariables[varName].unique
        };
  
        // Only add userValues if the unique flag is set to true
        if (userSettings.customVariables[varName].unique) {
          variable.userValues = Object.keys(userSettings.customVariables[varName].userValues);
        }
  
        returnArr.push(variable);
      }
    }
    return returnArr;
  };  
  const variables = {
    number: [...getVariablesOfType('number')],
    text: [...getVariablesOfType('text')],
    boolean: [...getVariablesOfType(['boolean'])],
    list: [...getVariablesOfType('list')]
  };
  const variableTypes = [
    { label: 'Number', value: 'number' },
    { label: 'Text', value: 'text' },
    { label: 'Boolean', value: 'boolean' },
    { label: 'List', value: 'list' }
  ];
  // Updated methods for 'list' to exclude '.index'
  const methods = {
    number: [
      { label: 'read', value: 'read', description: 'retrieves the value of' },
      { label: 'increment', value: 'increment', description: 'increases the value of' },
      { label: 'decrement', value: 'decrement', description: 'decreases the value of' },
      { label: 'set', value: 'set', description: 'sets the value of' }
    ],
    text: [
      { label: 'read', value: 'read', description: 'retrieves the text of' },
      { label: 'set', value: 'set', description: 'sets the text of' }
    ],
    boolean: [
      { label: 'read', value: 'read', description: 'retrieves the boolean value of' },
      { label: 'toggle', value: 'toggle', description: 'toggles the boolean value of' },
      { label: 'set', value: 'set', description: 'sets the boolean value of' }
    ],
    list: [
      { label: 'read', value: 'read', description: 'retrieves an item from' },
      { label: 'length', value: 'length', description: 'retrieves the length of' },
      { label: 'add', value: 'add', description: 'adds an item to' },
      { label: 'set', value: 'set', description: 'sets an item in' },
      { label: 'delete', value: 'delete', description: 'deletes an item from' },
      { label: 'includes', value: 'includes', description: 'checks if an item is included in' },
      { label: 'getposition', value: 'getposition', description: 'gets the position of an item in' },
      { label: 'clear', value: 'clear', description: 'clears all items from' }
    ]
  };

  const methodRequiresArgument = (method, variableType) => {
    const methodsRequiringArgument = {
      number: ['increment', 'decrement', 'set'],
      text: ['set'],
      boolean: ['set'],
      list: ['increment', 'decrement', 'set', 'add', 'delete', 'includes', 'getposition', 'read']
    };
    return methodsRequiringArgument[variableType]?.includes(method);
  };
  const getVariableType = (varName) => {
    for (const [type, vars] of Object.entries(variables)) {
      if (vars.some((v) => v.value === varName)) {
        return type;
      }
    }
    return null;
  };
  const handleVariableChange = (e) => {
    const selectedVariable = variables[variableType].find((v) => v.value === e.value);
    setVariable(e.value);
    // Check if variable is unique
    if (selectedVariable.unique) {
      // Prepare username options
      const options = [
        { label: 'Global', value: '' }, // No username in the variable string
        { label: 'All Users', value: 'all-users' },
        { label: 'Targeted User', value: '${username}' },
        // Add userValues as options
        ...selectedVariable.userValues.map((user) => ({ label: user, value: user })),
      ];
      setUsernameOptions(options);
      setUsername(''); // Default to 'Global'
    } else {
      setUsername(null); // No username field
      setUsernameOptions([]);
    }
  };
  const handleAddToExpression = () => {
    let expr = '${';
    if (username && username !== '') {
      expr += username + '.';
    }
    expr += variable + '.' + method;
    if (argument) {
      expr += '.' + argument;
    }
    expr += '}';

    const newExpression = expression ? expression + expr : expr;
    setExpression(newExpression);
    // Generate explanation for the new full expression
    const parsedExprs = parseExpressionString(newExpression);
    const newExplanation = generateExplanationFromParsed(parsedExprs);
    setExplanation(newExplanation);
    resetSelections();
  };
  const resetSelections = () => {
    setVariableType(null);
    setVariable(null);
    setMethod(null);
    setArgument('');
    setUsername(null);
    setUsernameOptions([]);
  };
  const openNestedBuilder = () => {
    setShowNestedBuilder(true);
  };
  const handleNestedInsert = (nestedExpr) => {
    const currentArgument = argument;
    const before = currentArgument.slice(0, argumentSelectionStart);
    const after = currentArgument.slice(argumentSelectionEnd);
    const newArgument = before + nestedExpr + after;

    setArgument(newArgument);

    // Update cursor position after insertion
    const newCursorPos = argumentSelectionStart + nestedExpr.length;
    setTimeout(() => {
      if (argumentRef.current) {
        argumentRef.current.setSelectionRange(newCursorPos, newCursorPos);
        argumentRef.current.focus();
      }
    }, 0);

    setShowNestedBuilder(false);
  };
  const handleClearExpression = () => {
    setExpression('');
    setExplanation('');
  };
  // Updated parsing functions to handle multiple expressions and nested username
  const parseExpressionString = (s) => {
    let index = 0;
    const length = s.length;
    const expressions = [];

    function parse() {
      if (s[index] !== '$' || s[index + 1] !== '{') {
        return null;
      }
      index += 2; // Skip over '${'
      let content = '';
      let nested = [];

      while (index < length) {
        if (s[index] === '$' && s[index + 1] === '{') {
          // Nested expression
          const nestedExpr = parse();
          if (nestedExpr) nested.push(nestedExpr);
        } else if (s[index] === '}') {
          index++; // Skip over '}'
          break;
        } else {
          content += s[index++];
        }
      }

      return { content: content.trim(), nested };
    }

    while (index < length) {
      if (s[index] === '$' && s[index + 1] === '{') {
        const expr = parse();
        if (expr) expressions.push(expr);
      } else {
        index++;
      }
    }

    return expressions;
  };
  const generateExplanationFromParsed = (parsedExprs) => {
    if (!parsedExprs || parsedExprs.length === 0) return '';
    return parsedExprs.map(generateExplanationForSingleExpr).join(' ');
  };
  const generateExplanationForSingleExpr = (parsedExpr, isNested = false) => {
    if (!parsedExpr) return '';

    // Split content into parts
    const { content, nested } = parsedExpr;
    const parts = splitExpression(content);
    if (!parts) return '';

    const { usernamePart, variablePart, methodPart, argPart } = parts;

    // Handle the case where variablePart is empty
    if (!variablePart) {
      if (typeof usernamePart === 'string') {
        if (usernamePart === 'username' || usernamePart === '${username}') {
          return 'the targeted user';
        } else if (usernamePart === 'all-users') {
          return 'all users';
        } else {
          return `user "${usernamePart}"`;
        }
      } else {
        // usernamePart is a nested expression
        return generateExplanationForSingleExpr(usernamePart, true);
      }
    }

    const varType = getVariableType(variablePart);
    const methodObj = methods[varType]?.find((m) => m.value === methodPart);
    const methodDescription = methodObj ? methodObj.description : methodPart;

    let userDesc = '';
    if (usernamePart) {
      if (typeof usernamePart === 'string') {
        if (usernamePart === '${username}' || usernamePart === 'username') {
          userDesc = 'for the targeted user';
        } else if (usernamePart === 'all-users') {
          userDesc = 'for all users';
        } else {
          userDesc = `for user "${usernamePart}"`;
        }
      } else {
        // usernamePart is a nested expression
        const nestedUsernameExplanation = generateExplanationForSingleExpr(usernamePart, true);
        userDesc = `for the user determined by (${nestedUsernameExplanation})`;
      }
    } else {
      userDesc = '';
    }

    // Handle nested arguments
    let argumentDesc = '';
    if (nested && nested.length > 0) {
      const nestedExplanations = nested.map((n) => generateExplanationForSingleExpr(n, true));
      argumentDesc = ` with the argument (${nestedExplanations.join(' ')})`;
    } else if (argPart) {
      argumentDesc = ` with the argument "${argPart}"`;
    }

    const action = `${methodDescription} the variable "${variablePart}"`;
    const userPart = userDesc ? ` ${userDesc}` : '';

    if (isNested) {
      return `${action}${userPart}${argumentDesc}`;
    } else {
      return `It ${action}${userPart}${argumentDesc}.`;
    }
  };
  const splitExpression = (expr) => {
    const parts = [];
    let buffer = '';
    let depth = 0;

    for (let i = 0; i < expr.length; i++) {
      const char = expr[i];
      if (char === '.' && depth === 0) {
        parts.push(buffer);
        buffer = '';
      } else {
        if (char === '$' && expr[i + 1] === '{') {
          depth++;
        } else if (char === '}') {
          depth--;
        }
        buffer += char;
      }
    }
    if (buffer) parts.push(buffer);

    let idx = 0;
    let usernamePart = '';
    let variablePart = '';
    let methodPart = '';
    let argPart = '';

    // Check if the first part is a nested expression
    if (parts[idx].startsWith('${') && parts[idx].endsWith('}')) {
      usernamePart = parseExpressionString(parts[idx])[0]; // Parse the nested expression
      idx++;
    } else if (
      parts[idx] === 'all-users' ||
      parts[idx] === '${username}' ||
      !getVariableType(parts[idx])
    ) {
      usernamePart = parts[idx];
      idx++;
    }

    variablePart = parts[idx++] || '';
    methodPart = parts[idx++] || '';
    argPart = parts.slice(idx).join('.') || '';

    return { usernamePart, variablePart, methodPart, argPart };
  };
  const handleVariableTypeChange = (e) => {
    setVariableType(e.value);
    setVariable(null);
    setMethod(null);
    setUsername(null);
    setUsernameOptions([]);
  };
  const handleArgumentSelect = (e) => {
    setArgumentSelectionStart(e.target.selectionStart);
    setArgumentSelectionEnd(e.target.selectionEnd);
  };
  // Function to handle copying text to clipboard and showing toast message
  const handleExpressionClick = (e) => {
    // Select all text in the InputTextarea
    e.target.select();
    // Copy selected text to clipboard
    navigator.clipboard.writeText(expression).then(() => {
      // Show toast message for 2 seconds
      toast.current.show({ severity: 'success', summary: 'Copied', detail: 'Expression copied to clipboard', life: 2000 });
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div className={`p-fluid ${showNestedBuilder ? 'blur-background' : ''}`} style={{width: '50vw', maxWidth: '1000px', minWidth: '350px', marginLeft: 'auto', marginRight: 'auto'}}>
      {/* Toast Component for showing notifications */}
      <Toast ref={toast} />
      <h3>String Builder</h3>
      <Grid container spacing={2}>
        {/* Variable Type Selection */}
        <Grid item xs={12}>
          <label htmlFor="variableType">Variable Type</label>
          <Dropdown id="variableType" value={variableType} placeholder="Select Variable Type" options={variableTypes} onChange={handleVariableTypeChange}
            style={styles.dropdown} />
        </Grid>
        {/* Variable Selection */}
        {variableType && (
          <Grid item xs={12}>
            <label htmlFor="variable">Variable</label>
            <Dropdown id="variable" value={variable} options={variables[variableType]} onChange={handleVariableChange} placeholder="Select Variable" 
              optionLabel="label" optionValue="value" style={styles.dropdown} />
          </Grid>
        )}
        {/* Username Dropdown */}
        {variable && usernameOptions.length > 0 && (
          <Grid item xs={12}>
            <label htmlFor="username">Username</label>
            <Dropdown id="username" value={username} options={usernameOptions} onChange={(e) => setUsername(e.value)} placeholder="Select Username Option"
              optionLabel="label" optionValue="value" style={styles.dropdown} />
          </Grid>
        )}
        {/* Method Selection */}
        {variable && (
          <Grid item xs={12}>
            <label htmlFor="method">Method</label>
            <Dropdown id="method" value={method} options={methods[variableType]} onChange={(e) => setMethod(e.value)} placeholder="Select Method"
              optionLabel="label" optionValue="value" style={styles.dropdown} />
          </Grid>
        )}
        {/* Argument Input */}
        {method && methodRequiresArgument(method, variableType) && (
          <Grid item xs={12}>
            <label htmlFor="argument">Argument</label>
            <InputTextarea id="argument" value={argument} onChange={(e) => setArgument(e.target.value)} placeholder="Enter Argument (supports mathematical expressions)"
              rows={3} autoResize ref={argumentRef} onSelect={handleArgumentSelect} style={styles.textfield} />
            <Grid item xs={12}>
              <Button label="Insert Variable" icon="pi pi-plus" className="p-button-text p-mr-2" onClick={openNestedBuilder} />
            </Grid>
          </Grid>
        )}
        {/* Add to Expression Button */}
        <Grid item xs={12} sx={{marginTop: 2}}>
          <Button label="Add to Expression" icon="pi pi-check" onClick={handleAddToExpression} disabled={!method} />
        </Grid>
        {/* Expression Display */}
        {expression && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <h4 className="p-mt-4">Current Expression:</h4>
              </Grid>
              <Grid item xs={12}>
                <InputTextarea value={expression} readOnly rows={3} autoResize style={styles.textfield} onClick={handleExpressionClick} />
              </Grid>
              <Grid item xs={12} sx={{marginTop: 2}}>
                <Button label="Clear Expression" icon="pi pi-times" className="p-button-danger p-mt-2" onClick={handleClearExpression} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* Explanation Display */}
        {explanation && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <h4 className="p-mt-3">Explanation:</h4>
              </Grid>
              <Grid item xs={12}>
                <p style={{marginTop: 0}}>{explanation}</p>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* Nested Variable Builder Dialog */}
        <Dialog header="Nested Variable Builder" visible={showNestedBuilder} style={{width: '50vw'}} modal onHide={() => setShowNestedBuilder(false)}>
          <NestedVariableBuilder onInsert={handleNestedInsert} variables={variables} styles={styles} variableTypes={variableTypes} methods={methods}
            getVariableType={getVariableType} methodRequiresArgument={methodRequiresArgument} />
        </Dialog>
      </Grid>
    </div>
  );
};

const NestedVariableBuilder = ({onInsert, variables, styles, variableTypes, methods, getVariableType, methodRequiresArgument}) => {
  const [variableType, setVariableType] = useState(null);
  const [variable, setVariable] = useState(null);
  const [method, setMethod] = useState(null);
  const [argument, setArgument] = useState('');
  const [username, setUsername] = useState(null);
  const [usernameOptions, setUsernameOptions] = useState([]);
  const argumentRef = useRef(null);
  const [argumentSelectionStart, setArgumentSelectionStart] = useState(0);
  const [argumentSelectionEnd, setArgumentSelectionEnd] = useState(0);
  const [showNestedBuilder, setShowNestedBuilder] = useState(false);

  const handleVariableChange = (e) => {
    const selectedVariable = variables[variableType].find((v) => v.value === e.value);
    setVariable(e.value);
    // Check if variable is unique
    if (selectedVariable.unique) {
      // Prepare username options
      const options = [
        { label: 'Global', value: '' }, // No username in the variable string
        { label: 'All Users', value: 'all-users' },
        { label: 'Targeted User', value: '${username}' },
        // Add userValues as options
        ...selectedVariable.userValues.map((user) => ({ label: user, value: user })),
      ];
      setUsernameOptions(options);
      setUsername(''); // Default to 'Global'
    } else {
      setUsername(null); // No username field
      setUsernameOptions([]);
    }
  };
  const handleInsert = () => {
    let expr = '${';
    if (username && username !== '') {
      expr += username + '.';
    }
    expr += variable + '.' + method;
    if (argument) {
      expr += '.' + argument;
    }
    expr += '}';
    onInsert(expr);
    // Reset selections after insert
    setVariableType(null);
    setVariable(null);
    setMethod(null);
    setArgument('');
    setUsername(null);
    setUsernameOptions([]);
  };
  const openNestedBuilder = () => {
    setShowNestedBuilder(true);
  };
  const handleNestedInsert = (nestedExpr) => {
    const currentArgument = argument;
    const before = currentArgument.slice(0, argumentSelectionStart);
    const after = currentArgument.slice(argumentSelectionEnd);
    const newArgument = before + nestedExpr + after;

    setArgument(newArgument);

    // Update cursor position after insertion
    const newCursorPos = argumentSelectionStart + nestedExpr.length;
    setTimeout(() => {
      if (argumentRef.current) {
        argumentRef.current.setSelectionRange(newCursorPos, newCursorPos);
        argumentRef.current.focus();
      }
    }, 0);

    setShowNestedBuilder(false);
  };
  const handleVariableTypeChange = (e) => {
    setVariableType(e.value);
    setVariable(null);
    setMethod(null);
    setUsername(null);
    setUsernameOptions([]);
  };
  const handleArgumentSelect = (e) => {
    setArgumentSelectionStart(e.target.selectionStart);
    setArgumentSelectionEnd(e.target.selectionEnd);
  };

  return (
    <div className="p-fluid" style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
      <Grid container spacing={2}>
        {/* Variable Type Selection */}
        <Grid item xs={12}>
          <label htmlFor="nestedVariableType">Variable Type</label>
          <Dropdown id="nestedVariableType" placeholder="Select Variable Type" value={variableType} options={variableTypes} onChange={handleVariableTypeChange}
            style={styles.dropdown} />
        </Grid>
        {/* Variable Selection */}
        {variableType && (
          <Grid item xs={12}>
            <label htmlFor="nestedVariable">Variable</label>
            <Dropdown id="nestedVariable" value={variable} options={variables[variableType]} onChange={handleVariableChange} placeholder="Select Variable"
              optionLabel="label" optionValue="value" style={styles.dropdown} />
          </Grid>
        )}
        {/* Username Dropdown */}
        {variable && usernameOptions.length > 0 && (
          <Grid item xs={12}>
            <label htmlFor="nestedUsername">Username</label>
            <Dropdown id="nestedUsername" value={username} options={usernameOptions} onChange={(e) => setUsername(e.value)} placeholder="Select Username Option"
              optionLabel="label" optionValue="value" style={styles.dropdown} />
          </Grid>
        )}
        {/* Method Selection */}
        {variable && (
          <Grid item xs={12}>
            <label htmlFor="nestedMethod">Method</label>
            <Dropdown id="nestedMethod" value={method} options={methods[getVariableType(variable)]} onChange={(e) => setMethod(e.value)} placeholder="Select Method"
              optionLabel="label" optionValue="value" style={styles.dropdown} />
          </Grid>
        )}
        {/* Argument Input */}
        {method && methodRequiresArgument(method, variableType) && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label htmlFor="nestedArgument">Argument</label>
              </Grid>
              <Grid item xs={12}>
                <InputTextarea id="nestedArgument" value={argument} onChange={(e) => setArgument(e.target.value)} placeholder="Enter Argument (supports mathematical expressions)"
                  rows={3} autoResize ref={argumentRef} onSelect={handleArgumentSelect} style={styles.textfield} />
              </Grid>
              <Grid item xs={12}>
                <Button label="Insert Variable" icon="pi pi-plus" className="p-button-text" onClick={openNestedBuilder} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* Insert Button */}
        <Grid item xs={12}>
          <Button label="Insert" icon="pi pi-check" onClick={handleInsert} disabled={!method} />
        </Grid>
        {/* Nested Variable Builder Dialog */}
        <Dialog header="Nested Variable Builder" visible={showNestedBuilder} style={{width: '50vw'}} modal onHide={() => setShowNestedBuilder(false)}>
          <NestedVariableBuilder onInsert={handleNestedInsert} variables={variables} styles={styles} variableTypes={variableTypes} methods={methods}
              getVariableType={getVariableType} methodRequiresArgument={methodRequiresArgument} />
        </Dialog>
      </Grid>
    </div>
  );
};

export default StringBuilder;