import React, { useContext, useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Grid, Slider, Typography } from '@mui/material';
import { Dropdown } from 'primereact/dropdown';
import useTriggerTemplates from '../../utils/useTriggerTemplates';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ColorModeContext } from '../../theme';
import { Divider } from 'primereact/divider';
import useTriggerPresets from '../../utils/useTriggerPresets';
import { useUserSettings } from '../../store/usersettings-context';
import EditActions from './editActions';
import useIsMobile from '../../utils/useIsMobile';

const NewTrigger = ({backgroundColor = '#5D3B9E', setOpenDialog = () => {}, setIsBlur = () => {}, editObj = {}}) => {
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const [triggerTemplates, setTriggerTemplates] = useTriggerTemplates();
    const [triggerPresets, setTriggerPresets] = useTriggerPresets();
    const { themeMode, toggleThemeMode, theme } = useContext(ColorModeContext);
    const [triggerOptions, setTriggerOptions] = useState([]);
    const [presetOptions, setPresetOptions] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState('');
    const [isEditing, setIsEditing] = useState(Object.keys(editObj).length > 0);
    const [newTrigger, setNewTrigger] = useState(Object.keys(editObj).length === 0 ? {} : editObj);
    const [newTriggerActions, setNewTriggerActions] = useState(Object.keys(editObj).length === 0 ? [] : editObj['actions']);
    const isMobile = useIsMobile();
    const sliderStyle = {
        '& .MuiSlider-thumb': {backgroundColor, opacity: 1}, 
        '& .MuiSlider-rail': {backgroundColor, opacity: 1}, 
        '& .MuiSlider-track': {backgroundColor, opacity: 1, border: 'none'}
    };
    const scrollableContent = {
        maxHeight: '65vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };
    
    useEffect(() => {
        const prepareTriggerOptions = () => {
            const newArr = [];
            Object.keys(triggerTemplates).map((type, index) => {
                const optionsObj = { name: triggerTemplates[type]['description'], code: type };
                newArr.push(optionsObj);
            });
            newArr.sort((a, b) => a.name.localeCompare(b.name));
            setTriggerOptions(newArr);
        };
        const preparePresetOptions = () => {
            const newArr = [];
            Object.keys(triggerPresets).map((type, index) => {
                const optionsObj = { name: triggerPresets[type]['description'], code: type };
                newArr.push(optionsObj);
            });
            newArr.sort((a, b) => a.name.localeCompare(b.name));
            setPresetOptions(newArr);
        };
        prepareTriggerOptions();
        preparePresetOptions();
    }, [triggerTemplates, triggerPresets]);
    useEffect(() => {
        if (Object.keys(newTrigger).length > 0) {
            saveNewTriggerActions();
        }
    }, [newTriggerActions]);

    const getTriggerSpecificParameters = (type) => {
        switch (type) {
            case 'timer':
            case 'chat_inactivity':
                return (
                    <React.Fragment>
                        {type === 'timer' && (<Typography>Adjust the interval rate</Typography>)}
                        {type === 'chat_inactivity' && (<Typography>Adjust the inactivity timeout</Typography>)}
                        <Slider sx={{...sliderStyle}} value={newTrigger[type] ?? 60} onChange={(e, v) => setNewTrigger((prev) => ({...prev, [type]: v}))}
                            min={60} max={3600} step={30} valueLabelDisplay='auto' valueLabelFormat={formatTime(newTrigger[type])} />
                    </React.Fragment>
                );
            case 'keyword':
                return (
                    <React.Fragment>
                        <InputText style={{width: '100%', backgroundColor}} placeholder='Keyword to look for' value={newTrigger[type]}
                            onChange={(e) => setNewTrigger((prev) => ({...prev, [type]: e.target.value}))} />
                    </React.Fragment>
                );
            case 'twitch_reward_id':
                return (
                    <React.Fragment>
                        <Button label='Click to select reward' onClick={() => console.log(userSettings)} />
                    </React.Fragment>
                );
        
            default:
                break;
        }
    };
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };
    const saveTriggerHandler = () => {
        const _triggers = [...userSettings.triggers];
        let index = -1;
        if (isEditing) {
            _triggers.find((o, i) => {
                if (o['id'] === newTrigger['id']) {
                    index = i;
                    return true;
                }
                return false;
            });
            if (index !== -1) {
                _triggers.splice(index, 1, newTrigger);
                update({triggers: [..._triggers]}).then(() => {
                    setOpenDialog(false);
                    setIsBlur(false);
                });
            }
        } else {
            let newIndex = 0;
            for (let i in _triggers) {
                _triggers[i]['id'] = parseInt(i);
                newIndex = newIndex + 1;
            }
            update({triggers: [..._triggers, {...newTrigger, id: parseInt(newIndex)}]}).then(() => {
                setOpenDialog(false);
                setIsBlur(false);
            });
        }
    };
    const getSaveValidity = () => {
        if (newTrigger['actions'].length === 0) return true;
        if (newTrigger['notes'].length < 1 || newTrigger['notes'].length > 100) return true;
        for (let msgObj of newTrigger['actions']) {
            if (msgObj['type'] === 'Delay') {
                if (msgObj['seconds'] < 1 || msgObj['seconds'] > 60) return true;
            } else if (msgObj['type'] === 'Compare') {
                if ('operator' in msgObj === false || 'value1' in msgObj === false || 'value2' in msgObj === false || 'actions' in msgObj === false) return true;
                if (msgObj['operator'] === '') return true;
                if (msgObj['value1'] === '') return true;
                if (msgObj['value1'].length > 500) return true;
                if (msgObj['value2'] === '') return true;
                if (msgObj['value2'].length > 500) return true;
                if (msgObj['actions'].length === 0) return true;
            } else if (msgObj['type'] === 'Macro') {
                if (!msgObj['macro']) return true;
            } else if (msgObj['type'] === 'Break') {
                continue;
            } else {
                if (msgObj['message'].length === 0) return true;
            }
        }
        for (let i in userSettings['triggers']) {
            const _trigger = {...userSettings['triggers'][i]};
            let isUnique = triggerTemplates[_trigger['trigger']]['unique'];
            if (newTrigger['trigger'] === _trigger['trigger'] && isUnique && !isEditing) return true;
            if (_trigger['notes'] === newTrigger['notes']) {
                if (_trigger['trigger'] === newTrigger['trigger']) {
                    if (JSON.stringify(_trigger['actions']) === JSON.stringify(newTrigger['actions'])) {
                        if (!isEditing) return true;
                    }
                }
            }
        }
        return false;
    };
    const saveNewTriggerActions = () => {
        setNewTrigger((prev) => ({...prev, actions: newTriggerActions}));
    };
    const createFromPreset = (code) => {
        setNewTrigger(triggerPresets[code]);
        setNewTriggerActions(triggerPresets[code]['actions']);
    };
    

    return (
        <DialogComponent footer={isEditing ? 'Edit your existing trigger' : 'Set up a new monitor and actions to execute'} maxHeight='75vh' minWidth='60vw'>
            <Grid container spacing={2} sx={{...scrollableContent, marginLeft: '0px', padding: '8px'}}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Type and Condition</Typography>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Dropdown style={{width: '100%', backgroundColor}} placeholder='Select a Trigger' options={triggerOptions} optionLabel='name' 
                        value={Object.keys(newTrigger).length > 0 && {name: triggerTemplates[newTrigger['trigger']]['description'], code: newTrigger['trigger']}} 
                        onChange={(e) => setNewTrigger({trigger: e.value.code, [e.value.code]: triggerTemplates[e.value.code][e.value.code], notes: triggerTemplates[e.value.code]['notes'], actions: []})} />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Dropdown style={{width: '100%', backgroundColor}} placeholder='Create from preset' options={presetOptions} optionLabel='name'
                        value={{name: selectedPreset, code: selectedPreset}} onChange={(e) => createFromPreset(e.value.code)} />
                </Grid>
                {Object.keys(newTrigger).length > 0 && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <InputText style={{width: '100%', backgroundColor}} placeholder='Trigger Name' value={newTrigger['notes']} 
                                onChange={(e) => setNewTrigger((prev) => ({...prev, notes: e.target.value}))} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>{`${triggerTemplates[newTrigger.trigger].info}`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {getTriggerSpecificParameters(newTrigger['trigger'])}
                        </Grid>
                        <Divider style={{marginTop: '32px'}} />
                        <React.Fragment>
                            {('actions' in newTrigger) && (
                                <EditActions actionsArr={newTriggerActions} setActionsArr={setNewTriggerActions} backgroundColor={backgroundColor} userSettings={userSettings}
                                    save={(newActionsArr) => setNewTriggerActions(newActionsArr)} />
                            )}
                            <Grid item xs={12} sx={{display: 'flex', flexDirection: 'row-reverse'}}>
                                <Button severity='success' disabled={getSaveValidity()} style={{marginLeft: '16px'}} onClick={saveTriggerHandler}>Save</Button>
                                <Button severity='danger' onClick={() => {
                                    setOpenDialog(false); 
                                    setIsBlur(false);
                                }}>Close</Button>
                            </Grid>
                        </React.Fragment>
                    </React.Fragment>
                )}
            </Grid>
        </DialogComponent>
    );
};

export default NewTrigger;