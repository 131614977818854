import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ColorPicker } from 'primereact/colorpicker';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

/**
 * @component ColorPickerButton
 * @description A button that brings up a color selection overlay panel. Contains pre-selected colors and a PrimeReact ColorPicker.
 * @param {string} backgroundColor - The current background color in hex format.
 * @param {function} setBackgroundColor - Function to set the background color.
 */
const ColorPickerButton = ({ backgroundColor, setBackgroundColor }) => {
  const overlayPanel = useRef(null);
  const [doAnimateBackground, setDoAnimateBackground] = useState(false);
  const [showRefreshMessage, setShowRefreshMessage] = useState(false);
  const styles = {
    preselectButton: {
        width: '30px',
        height: '30px',
        cursor: 'pointer'
    },
    picker: {
        marginTop: '10px',
    },
    panelButton: {
        marginRight: '16px',
        color: 'var(--complementary-color)'
    },
    refreshMessage: {
      marginTop: '10px',
      color: 'var(--complementary-color)',
      fontSize: '0.85em',
      width: '190px'
    }
  };
  const preSelectedColors = [
    '#412C69', '#2D3F69', '#01610E', '#333330', '#7D480B',
    '#7C2A82', '#400019', '#822222', '#0A0D07', '#104F3E'
  ];

  useEffect(() => {
    const storedValue = localStorage.getItem('doAnimateBackground');
    if (storedValue) {
      setDoAnimateBackground(storedValue === 'true');
    } else {
      setDoAnimateBackground(true);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    const newValue = e.checked;
    setDoAnimateBackground(newValue);
    localStorage.setItem('doAnimateBackground', newValue.toString());
    setShowRefreshMessage(true);
  };

  const onColorSelect = (color) => {
    setBackgroundColor(color);
    overlayPanel.current.hide();
  };

  return (
    <React.Fragment>
      <Button size='small' style={styles.panelButton} onClick={(e) => {
            overlayPanel.current.toggle(e);
            setShowRefreshMessage(false);
          }
        } label='Theme' text />
      <OverlayPanel ref={overlayPanel}>
        <div className="pre-selected-colors" style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '5px' }}>
          {preSelectedColors.sort().map((color) => (
            <div style={{...styles.preselectButton, backgroundColor: color, border: backgroundColor === color ? '1px solid #EEEEEE' : '1px solid var(--complementing-color)'}}
                key={color} onClick={() => onColorSelect(color)} />
          ))}
        </div>
        <ColorPicker format='hex' value={backgroundColor} onChange={(e) => setBackgroundColor(`#${e.value}`)} style={styles.picker} inline />
        <br />
        <Checkbox id='doAnimateBgCheck' onChange={handleCheckboxChange} checked={doAnimateBackground} />
        <label style={{marginLeft: '8px'}} htmlFor='doAnimateBgCheck'>Animate Background</label>
        {showRefreshMessage && (
          <div style={styles.refreshMessage}>
            Changes will take effect after refreshing the page.
          </div>
        )}
      </OverlayPanel>
    </React.Fragment>
  );
};

ColorPickerButton.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  setBackgroundColor: PropTypes.func.isRequired,
};

export default ColorPickerButton;