import { Grid } from '@mui/material';
import React from 'react';
import useIsMobile from '../../utils/useIsMobile';

const MainView = ({children}) => {
    const isMobile = useIsMobile();
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
        'scrollbar-width': 'none' // For Firefox
    };
    const style = {
        maxHeight: '80vh', 
        overflowY: 'auto', 
        padding: !isMobile && '16px', 
        paddingTop: '0px', 
        paddingRight: isMobile ? '20px' : '32px'
    };

    return (
        <React.Fragment>
            <Grid container sx={{...hideScrollbar, ...style}}>
                {children}
            </Grid>
        </React.Fragment>
    );
};

export default MainView;