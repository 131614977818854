import React, { useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Grid, Typography } from '@mui/material';
import { useUserSettings } from '../../store/usersettings-context';
import useIsMobile from '../../utils/useIsMobile';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const NewMacro = ({backgroundColor = '#5D3B9E', setOpenDialog = () => {}, setIsBlur = () => {}, editObj = {}}) => {
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const [macroName, setMacroName] = useState(Object.keys(editObj).length === 0 ? '' : editObj['title']);
    const [originalMacroName, setOriginalMacroName] = useState(macroName);
    const [isEditing, setIsEditing] = useState(Object.keys(editObj).length > 0);
    const [hasChangedMacroName, setHasChangedMacroName] = useState(false);
    const isMobile = useIsMobile();
    const [warning, setWarning] = useState('');
    const scrollableContent = {
        maxHeight: '65vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };

    const updateMacroName = (newMacroName) => {
        if (newMacroName.length <= 255) {
            setMacroName(newMacroName);
            setWarning('');
        } else {
            setMacroName(newMacroName.slice(0, 255));
            setWarning('Macro name cannot exceed 255 characters.');
        }
        if (isEditing && newMacroName !== originalMacroName) {
            setHasChangedMacroName(true);
        } else {
            setHasChangedMacroName(false);
            setWarning('');
        }
        if (macroInUse()) {
            setWarning('This macro is in use, saving will update all macro references!');
        }
    };
    const saveMacroHandler = () => {
        const _macros = {...userSettings.macros};
        _macros[macroName] = Object.keys(editObj).length === 0 ? {actions: [], title: macroName} : {...editObj, title: macroName};
        update({macros: _macros}, true);
        if (hasChangedMacroName === true) deletekey(['macros', originalMacroName]);
        // Go through commands and triggers to update macro references
        if (macroInUse()) {
            const _customCommands = {...userSettings['customCommands']};
            const _triggers = [...userSettings['triggers']];
            for (let cmdName of Object.keys(userSettings['customCommands'])) {
                const _command = {...userSettings['customCommands'][cmdName]};
                const _actions = [..._command['actions']];
                for (let actionIndex in _actions) {
                    if (_actions[actionIndex]['type'] === 'Compare') {
                        const compareActions = [..._actions[actionIndex]['actions']];
                        for (let compareIndex in compareActions) {
                            if (compareActions[compareIndex]['type'] === 'Macro') {
                                if (compareActions[compareIndex]['macro'] === originalMacroName) {
                                    _customCommands[cmdName]['actions'][actionIndex]['actions'][compareIndex]['macro'] = macroName;
                                }
                            } else {
                                continue;
                            }
                        }
                    } else if (_actions[actionIndex]['type'] === 'Macro') {
                        if (_actions[actionIndex]['macro'] === originalMacroName) {
                            _customCommands[cmdName]['actions'][actionIndex]['macro'] = macroName;
                        }
                    } else {
                        continue;
                    }
                }
            }
            for (let triggerIndex in _triggers) {
                const _trigger = {..._triggers[triggerIndex]};
                const _actions = [..._trigger['actions']];
                for (let actionIndex in _actions) {
                    if (_actions[actionIndex]['type'] === 'Compare') {
                        const compareActions = [..._actions[actionIndex]['actions']];
                        for (let compareIndex in compareActions) {
                            if (compareActions[compareIndex]['type'] === 'Macro') {
                                if (compareActions[compareIndex]['macro'] === originalMacroName) {
                                    _triggers[triggerIndex]['actions'][actionIndex]['actions'][compareIndex]['macro'] = macroName;
                                }
                            } else {
                                continue;
                            }
                        }
                    } else if (_actions[actionIndex]['type'] === 'Macro') {
                        if (_actions[actionIndex]['macro'] === originalMacroName) {
                            _triggers[triggerIndex]['actions'][actionIndex]['macro'] = macroName;
                        }
                    } else {
                        continue;
                    }
                }
            }
            update({customCommands: _customCommands}, true);
            update({triggers: _triggers}, true);
        }
        setOpenDialog(false);
        setIsBlur(false);
    };
    const macroExists = () => macroName in userSettings['macros'];
    const macroInUse = () => {
        let returnVal = false;
        const existsInActions = (actions = []) => {
            for (let action of actions) {
                if (action['type'] === 'Compare') {
                    const _compareActions = [...action['actions']];
                    if (existsInActions(_compareActions)) returnVal = true;
                } else if (action['type'] === 'Macro') {
                    if (action['macro'] === originalMacroName) returnVal = true;
                } else {
                    continue;
                }
            }
        };
        // 1. Check commands
        for (let cmdName of Object.keys(userSettings['customCommands'])) {
            const _command = {...userSettings['customCommands'][cmdName]};
            const _actions = [..._command['actions']];
            if (existsInActions(_actions)) returnVal = true;
        }
        // 2. Check triggers
        for (let trigger of userSettings['triggers']) {
            const _actions = [...trigger['actions']];
            if (existsInActions(_actions)) returnVal = true;
        }
        return returnVal;
    };

    return (
        <DialogComponent footer={isEditing ? 'Edit your existing macro' : 'Create a new macro'} maxHeight='75vh' minWidth='25vw'>
            <Grid container spacing={2} sx={{...scrollableContent, marginLeft: '0px', padding: '8px'}}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Command</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputText style={{width: '100%', backgroundColor}} placeholder='Macro Name' value={macroName} 
                        onChange={(e) => {
                            updateMacroName(e.target.value);
                            if (e.target.value !== originalMacroName) {
                                setHasChangedMacroName(true);
                            }
                        }} />
                    {warning !== '' && <Typography variant='body2' color='error'>{warning}</Typography>}
                </Grid>
                <Grid item xs={12}>
                    <Button label='Save' severity='success' size='small' style={{ width: '25%' }} onClick={saveMacroHandler}
                        disabled={macroExists()} />
                </Grid>
            </Grid>
        </DialogComponent>
    );
};

export default NewMacro;