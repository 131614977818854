import { Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import TriggerItem from './TriggerItem';
import useTriggerTemplates from '../../utils/useTriggerTemplates';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';

const TriggerGrid = ({backgroundColor = '#5D3B9E', openDialogComponent = () => {}, userSettings = {}, update = () => {}}) => {
    const [triggerTemplates, setTriggerTemplates] = useTriggerTemplates();
    const isMobile = useIsMobile();
    const isLarge = useWindowSizeThreshold(1280, true);
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const cardStyle = {
        height: '150px',
        background: `radial-gradient(circle, ${colorScheme.background75} 0%, ${colorScheme.background25} 100%)`,
        border: `2px solid ${colorScheme.background75}`
    };
    const scrollableContent = {
        maxHeight: '80vh',
        overflowY: 'auto',
        padding: '16px',
        marginTop: '16px',
        paddingTop: '0px',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };

    useEffect(() => {
        if (!userSettings.triggers) {
            update({triggers: []});
        }
        const cleanupTriggers = () => {
            const cleanedArr = [];
            for (let index in userSettings['triggers']) {
                const currTrigger = {...userSettings['triggers'][index]};
                const cleanedTrigger = {};
                const triggerType = currTrigger['trigger'];
                const triggerTemplate = {...triggerTemplates[triggerType]};
                for (let key of Object.keys(triggerTemplate)) {
                    if (key === 'info') continue;
                    cleanedTrigger[key] = currTrigger[key];
                }
                cleanedTrigger['trigger'] = triggerType;
                cleanedTrigger['id'] = parseInt(index);
                cleanedArr.push(cleanedTrigger);
            }
            if (JSON.stringify(cleanedArr) !== JSON.stringify(userSettings['triggers'])) update({triggers: cleanedArr});
        };
        cleanupTriggers();
    }, []);

  return (
    <Grid container spacing={2} sx={scrollableContent}>
        {!!userSettings.triggers ? (
            <React.Fragment>
                <Grid item xs={(isMobile && isLarge) ? 12 : (!isMobile && isLarge) ? 3 : 2} key={-1}>
                    <Card sx={{...cardStyle}}>
                        <CardActionArea disableRipple disableTouchRipple sx={{height: cardStyle.height}} onClick={() => openDialogComponent('newtrigger')}>
                            <CardContent sx={{textAlign: 'center'}}>
                                <ListItemText primary={<h3 style={{margin: '0px', padding: '0px', color: 'var(--complementary-color)'}}>+ Add new trigger</h3>} />
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                {userSettings.triggers.map((triggerObj, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={(isMobile && isLarge) ? 12 : (!isMobile && isLarge) ? 3 : 2} key={triggerObj['id']}>
                            <TriggerItem userSettings={userSettings} update={update} triggerObj={triggerObj} cardStyle={cardStyle} openDialogComponent={openDialogComponent} />
                        </Grid>
                    </React.Fragment>
                ))}
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Typography>Preparing...</Typography>
            </React.Fragment>
        )}
    </Grid>
  );
};

export default TriggerGrid;