import React, { useEffect, useState } from 'react';
import useIsMobile from '../../utils/useIsMobile';
import { color, motion } from 'framer-motion';
import { Card, CardActionArea, CardContent, ListItemText, Typography } from '@mui/material';
import { Button } from 'primereact/button';
import { useUser } from '../../context/UserContext';

const PresetCard = ({preset = {}, presetName = '', isSelected = false, setSelected = () => {}, backgroundColor = '#5D3B9E', isCustom = false, update = () => {}}) => {
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const isMobile = useIsMobile();
    const background100 = `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 1)`;
    const background75 = `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`;
    const background50 = `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`;
    const background25 = `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`;
    const styles = {
        card: {
            height: '250px',
            position: 'relative',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            transition: 'background-color 0.3s ease',
            borderRadius: isMobile ? '10px' : '20px',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1,
              transition: 'opacity 0.3s ease',
            },
            '& > *': {
              position: 'relative',
              zIndex: 2,
            },
            '&:hover': !isMobile && ({
              transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s', // Transitions for the effects
            }),
        },
        cardText: {
            textAlign: 'center',
            textShadow: '0px 0px 25px #000000, 0px 0px 20px #000000, 0px 0px 10px #000000, 0px 0px 15px #000000, 0px 0px 5px #000000',
            position: 'relative',
            top: isMobile ? '50px' : '75px', // Initially set the primary text 75px from the top
            opacity: 0, // Initially set opacity to 0 for both primary and secondary text
            transition: 'top 0.3s ease, opacity 0.3s ease' // Add transitions for top and opacity
        },
        customText: {
          position: 'absolute',
          top: 20,
          left: 16,
          textShadow: '0px 0px 10px #000000, 0px 0px 5px #000000'
        },
        customButton: {
          position: 'absolute',
          top: 8,
          right: 16
        }
    };
    const truncateText = (text, maxLength) => {
        const isTruncated = text.length > maxLength;
        const truncatedText = isTruncated ? text.substring(0, maxLength) + '...' : text;
        return { truncatedText, isTruncated };
    };
    const preloadImages = () => {
      const imagesToPreload = [preset.image, preset.image_selected];
      imagesToPreload.forEach(imageUrl => {
          const img = new Image();
          img.src = imageUrl;
      });
    };
    const { truncatedText, isTruncated } = truncateText(preset['description'], 150);
    let imageUrl = preset.hasOwnProperty('image') ? preset['image'] : '';
    if (isSelected && preset['image_selected'] !== '') {
        imageUrl = preset['image_selected'];
    };
    const cardStyle = {...styles.card, backgroundImage: `url(${imageUrl})`};
    const [isHovered, setIsHovered] = useState(false);
    const hoverAnimation = {
        scale: [1, 1.025, 1],
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            times: [0, 0.5, 1] // Start, Midway, End
        }
    };
    const clickAnimation = {
        scale: [1, 0.95, 1],
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            times: [0, 0.5, 1] // Start, Midway, End
        }
    };

    const deleteHandler = async (e) => {
      e.stopPropagation();
      try {
        const response = await fetch(`${apiUrl}/user/personality/${encodeURIComponent(presetName)}?token=${authCtx.token}`, {
            method: 'DELETE'
        });
        if (response.ok) {
          update();
        }
      } catch (err) {
        console.log(err);
      }
    };

    useEffect(() => {
      preloadImages();
    }, [preset.image, preset.image_selected]);

    return (
        <motion.div whileHover={!isMobile && hoverAnimation} whileTap={!isMobile && clickAnimation} onHoverStart={() => setIsHovered(true)} onHoverEnd={() => setIsHovered(false)}>
          <Card className='ai-preset-card' sx={{...cardStyle, backgroundImage: `url(${imageUrl})`, border: isSelected ? `2px solid ${background100}` : 'none',
              '&::before': isSelected ? { ...cardStyle['&::before'], backgroundColor: background25 } : cardStyle['&::before']}}>
            <CardActionArea disableTouchRipple disableRipple sx={{ height: styles.card.height }}
              onClick={() => setSelected((prev) => prev === presetName ? '' : presetName)}>
              <CardContent>
                    {!isSelected && !isMobile && (
                        <ListItemText primary={presetName} secondary={isHovered && truncatedText} sx={{...styles.cardText, top: isHovered ? '0' : '75px', 
                            opacity: isHovered ? '1' : '0'}} />
                    )}
                    {(!isHovered || isSelected || isMobile) && (
                        <ListItemText primary={presetName} sx={{position: styles.cardText.position, top: styles.cardText.top, textAlign: styles.cardText.textAlign, 
                            textShadow: styles.cardText.textShadow}} />
                    )}
                    {isCustom && (
                      <Typography sx={styles.customText}>Auto-Generated</Typography>
                    )}
                    {isCustom && isHovered && !isSelected && (
                      <Button tooltipOptions={{ position: 'top' }} tooltip={`Delete ${presetName}`} severity='danger' style={styles.customButton} 
                        size='small' icon='pi pi-times' onClick={deleteHandler} />
                    )}
              </CardContent>
            </CardActionArea>
          </Card>
        </motion.div>
      );
};

export default PresetCard;