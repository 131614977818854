import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import useCopyArray from '../../utils/useCustomVariableCopyArray';
import useIsMobile from '../../utils/useIsMobile';

const VariableCopyDropdown = ({ userSettings = {}, setSnackAlert = () => {}, filterWord = '' }) => {
    const { getCopyArray } = useCopyArray();
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const isMobile = useIsMobile();
    const style = {
        width: isMobile && '100%'
    };

    useEffect(() => {
        const tmpArr = [];
        Object.keys(userSettings['customVariables']).forEach((code) => {
            if (filterWord === '' || code.includes(filterWord)) {
                const variable = userSettings['customVariables'][code];
                const copyArr = getCopyArray(code, variable['datatype'], variable['unique'] ?? false);
                copyArr.forEach(copyCode => {
                    tmpArr.push({
                        name: copyCode,
                        code: copyCode
                    });
                });
            }
        });
        setDropdownOptions(tmpArr);
    }, [filterWord, userSettings, getCopyArray]); // Add relevant dependencies

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setSnackAlert((prev) => ({ ...prev, open: true, severity: 'success', text: `Copied ${text} to clipboard!` }));
    };

    return (
        <Dropdown 
            value={null} 
            options={dropdownOptions}
            optionLabel='name'
            onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyToClipboard(e.value.code);
            }}
            placeholder="Select Copy Method"
            className="mr-2 mb-2"
            style={style}
        />
    );
};

export default VariableCopyDropdown;