import { Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from '@mui/material';
import React from 'react';
import CommandItem from './CommandItem';
import useIsMobile from '../../utils/useIsMobile';
import useIsLarge from '../../utils/useIsLarge';

const CommandGrid = ({backgroundColor = '#5D3B9E', openDialogComponent = () => {}, userSettings = {}, update = () => {}, deletekey = () => {}}) => {
    const isMobile = useIsMobile();
    const isLarge = useIsLarge();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const cardStyle = {
        height: '150px',
        background: `radial-gradient(circle, ${colorScheme.background75} 0%, ${colorScheme.background25} 100%)`,
        border: `2px solid ${colorScheme.background75}`
    };
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
        'scrollbar-width': 'none' // For Firefox
    };

  return (
    <Grid container spacing={2} sx={{...hideScrollbar, maxHeight: '80vh', overflowY: 'auto', padding: '16px', paddingBottom: isMobile && '72px', marginTop: isMobile ? '8px' : '16px', paddingTop: '0px'}}>
        {!!userSettings.customCommands ? (
            <React.Fragment>
                <Grid item xs={isMobile ? 12 : isLarge ? 3 : 2} key={-1}>
                    <Card sx={{...cardStyle}}>
                        <CardActionArea disableRipple disableTouchRipple sx={{height: cardStyle.height}} onClick={() => openDialogComponent('newcommand')}>
                            <CardContent sx={{textAlign: 'center'}}>
                                <ListItemText primary={<h4 style={{color: 'var(--complementary-color)', padding: '0px', margin: '0px'}}>+ Add new command</h4>} />
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                {Object.keys(userSettings['customCommands']).map((commandName, index) => (
                    <Grid item xs={(isMobile && isLarge) ? 6 : (!isMobile && isLarge) ? 3 : 2} key={index}>
                        <CommandItem commandObj={{...userSettings['customCommands'][commandName]}} cardStyle={cardStyle} 
                            openDialogComponent={openDialogComponent} userSettings={userSettings} update={update} deletekey={deletekey} />
                    </Grid>
                ))}
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Typography>Preparing...</Typography>
            </React.Fragment>
        )}
    </Grid>
  );
};

export default CommandGrid;