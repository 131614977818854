import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { FormControlLabel, Grid, Typography } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useUserSettings } from '../../store/usersettings-context';
import EditActions from './editActions';
import useIsMobile from '../../utils/useIsMobile';
import { Checkbox } from 'primereact/checkbox';
import useComplementaryTextColor from '../../utils/useComplementaryTextColor';

const NewCommand = ({backgroundColor = '#5D3B9E', setOpenDialog = () => {}, setIsBlur = () => {}, editObj = {}}) => {
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const [commandName, setCommandName] = useState(Object.keys(editObj).length === 0 ? `${userSettings['commandDelimiter']}` : `${userSettings['commandDelimiter']}${editObj['title']}`);
    const [originalCommandName, setOriginalCommandName] = useState(commandName);
    const [newCommand, setNewCommand] = useState(Object.keys(editObj).length === 0 ? {actions: [], title: '', type: '', value: ''} : editObj);
    const [newCommandActions, setNewCommandActions] = useState(Object.keys(editObj).length === 0 ? [] : editObj['actions']);
    const [isEditing, setIsEditing] = useState(Object.keys(editObj).length > 0);
    const [hasChangedCommandName, setHasChangedCommandName] = useState(false);
    const [complementaryColor, setComplementaryColor] = useComplementaryTextColor(backgroundColor);
    const isMobile = useIsMobile();
    const scrollableContent = {
        maxHeight: '65vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    };

    useEffect(() => {
        if (Object.keys(newCommand).length > 0) {
            setNewCommand((prev) => ({...prev, actions: newCommandActions}));
        }
    }, [newCommandActions, commandName]);

    const getSaveValidity = () => {
        if (Object.keys(newCommand).length === 0) return true;
        if (newCommand['actions'].length === 0) return true;
        if (commandName.length < 2) return true;
        for (let msgObj of newCommand['actions']) {
            if (msgObj['type'] === 'Delay') {
                if (msgObj['seconds'] < 1 || msgObj['seconds'] > 60) return true;
            } else if (msgObj['type'] === 'Compare') {
                if ('operator' in msgObj === false || 'value1' in msgObj === false || 'value2' in msgObj === false || 'actions' in msgObj === false) return true;
                if (msgObj['operator'] === '') return true;
                if (msgObj['value1'] === '') return true;
                if (msgObj['value1'].length > 500) return true;
                if (msgObj['value2'] === '') return true;
                if (msgObj['value2'].length > 500) return true;
                if (msgObj['actions'].length === 0) return true;
            } else if (msgObj['type'] === 'Macro') {
                if (!msgObj['macro']) return true;
            } else if (msgObj['type'] === 'Break') {
                continue;
            } else {
                if (msgObj['message'].length === 0) return true;
            }
        }
        return false;
    };
    const saveCommandHandler = () => {
        const _commands = {...userSettings.customCommands};
        let index = -1;
        _commands[commandName.substring(1, commandName.length)] = {...newCommand};
        update({customCommands: _commands}, true).then((res) => {
            if (hasChangedCommandName === true) deletekey(['customCommands', originalCommandName.substring(1, originalCommandName.length)]);
        });
        setOpenDialog(false);
        setIsBlur(false);
    };
    const updateCommandName = (newCommandName) => {
        if (newCommandName === '') {
            setCommandName(`${userSettings['commandDelimiter']}`);
            setNewCommand((prev) => ({...prev, title: `${userSettings['commandDelimiter']}`}));
        } else {
            setCommandName(newCommandName.replace(/\s/g, ''));
            setNewCommand((prev) => ({...prev, title: newCommandName.substring(1, newCommandName.length).replace(/\s/g, '')}));
            if (isEditing && newCommandName !== originalCommandName) {
                setHasChangedCommandName(true);
            } else {
                setHasChangedCommandName(false);
            }
        }
    };
    const toggleDisableCommand = (e, enable) => {
        e.stopPropagation();
        if (enable) {
            update({disabledCommands: [...userSettings['disabledCommands'].filter((cmd) => cmd != editObj['title'])]});
        } else {
            update({disabledCommands: [...userSettings['disabledCommands'], editObj['title']]});
        }
    };
    const toggleIsAdminCommand = (e, enable) => {
        e.stopPropagation();
        if (enable) {
            console.log({...userSettings['permissions'], adminCommands: [...userSettings['permissions']['adminCommands'], editObj['title']]});
            update({permissions: {...userSettings['permissions'], adminCommands: [...userSettings['permissions']['adminCommands'], editObj['title']]}});
        } else {
            console.log({permissions: {...userSettings['permissions'], adminCommands: userSettings['permissions']['adminCommands'].filter((cmd) => cmd != editObj['title'])}});
            update({permissions: {...userSettings['permissions'], adminCommands: userSettings['permissions']['adminCommands'].filter((cmd) => cmd != editObj['title'])}});
        }
    };
    const checkIfAdminCommand = (command) => {
        if ('permissions' in userSettings === false) {
            update({permissions: {}});
            return false;
        }
        if ('adminCommands' in userSettings['permissions'] === false) {
            update({permissions: {...userSettings['permissions'], adminCommands: []}});
            return false;
        }
        return (userSettings['permissions']['adminCommands'].includes(command));
    };

  return (
    <DialogComponent footer={isEditing ? 'Edit your existing command' : 'Set up a new command with actions to execute'} maxHeight='75vh' minWidth='60vw'>
        <Grid container spacing={2} sx={{...scrollableContent, marginLeft: '0px', padding: '8px'}}>
            <Grid item xs={12}>
                <h1 style={{margin: '0px', padding: '0px', color: 'var(--complementary-color)'}}>Command</h1>
            </Grid>
            <Grid item xs={12}>
                <InputText style={{width: '100%', backgroundColor}} placeholder='Command Name' value={commandName} 
                    onChange={(e) => updateCommandName(e.target.value)} />
            </Grid>
            {isMobile && Object.keys(editObj).length > 0 && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <FormControlLabel sx={{marginTop: '8px', marginBottom: '8px'}} control={
                            <Checkbox onChange={(e) => toggleDisableCommand(e, e.checked)} style={{marginRight: '16px', marginLeft: '12px'}}
                                checked={!userSettings['disabledCommands'].includes(editObj['title'])} />
                            } label={'Enabled'} labelPlacement='end' />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel sx={{marginTop: '8px', marginBottom: '8px'}} control={
                            <Checkbox onChange={(e) => toggleIsAdminCommand(e, e.checked)} style={{marginRight: '16px', marginLeft: '12px'}}
                                checked={checkIfAdminCommand(editObj['title'])} />
                            } label={'Admin Command'} labelPlacement='end' />
                    </Grid>
                </React.Fragment>
            )}
            <React.Fragment>
                <Divider style={{marginTop: '32px'}} />
                <React.Fragment>
                    {('actions' in newCommand) && (
                        <EditActions actionsArr={newCommandActions} setActionsArr={setNewCommandActions} backgroundColor={backgroundColor} userSettings={userSettings}
                            save={(newActionsArr) => setNewCommand((prev) => ({...prev, actions: newActionsArr}))} textColor={complementaryColor} />
                    )}
                    <Grid item xs={12} sx={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <Button severity='success' disabled={getSaveValidity()} style={{marginLeft: '16px'}} onClick={saveCommandHandler}>Save</Button>
                        {!isMobile && (<Button severity='danger' onClick={() => {setOpenDialog(false); setIsBlur(false);}}>Close</Button>)}
                        {/*<Button severity='info' size='small' label='DevTest' onClick={() => console.log(newCommand)} />*/}
                    </Grid>
                </React.Fragment>
            </React.Fragment>
        </Grid>
    </DialogComponent>
  );
};

export default NewCommand;