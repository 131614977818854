import { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';

const apiUrl = process.env.REACT_APP_API_URL;

const useBotJobs = () => {
    const authCtx = useUser();
    const [botJobs, setBotJobs] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchStreamersData = async () => {
            try {
                const response = await fetch(`${apiUrl}/user/botjobs?token=${authCtx.token}`);
                if (response.ok) {
                    const res = await response.json();
                    setBotJobs(res['jobs']);
                } else {
                    setBotJobs([]);
                }
            } catch (e) {
                console.log(e);
            }
        };

        // Fetch data immediately on mount
        fetchStreamersData();

        // Set interval to fetch data every 5 seconds
        const intervalId = setInterval(fetchStreamersData, 5000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [authCtx.token]);

    const postJob = async (type = 'job', length = 3600) => {
        if (length > 3600) return;
        try {
            const response = await fetch(`${apiUrl}/user/botjobs?token=${authCtx.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({type, length})
            });
            const res = await response.json();
            if (response.ok) {
                setBotJobs(res['jobs']);
            } else {
                setError(res['error'])
            }
        } catch (e) {
            console.log(e);
        }
    };

    const clearJobs = async () => {
        try {
            const response = await fetch(`${apiUrl}/user/botjobs?token=${authCtx.token}`, {method: 'DELETE'});
            const res = await response.json();
            if (response.ok) {
                setBotJobs([]);
            } else {
                setError(res['error'])
            }
        } catch (e) {
            console.log(e);
        }
    };

    return { botJobs, setBotJobs, postJob, clearJobs };
};

export default useBotJobs;