import { useState } from "react";

const useTriggerPresets = () => {
    const [triggerPresets, setTriggerPresets] = useState({
        "Welcome New User": {
            "notes": "Welcome Message",
            "description": "Welcome Message",
            "trigger": "first_time_message",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Welcome ${username} to the stream"
                }
            ]
        },
        "Vibe Check": {
            "notes": "Chat Inactivity",
            "description": "Vibe Check",
            "chat_inactivity": 900,
            "trigger": "chat_inactivity",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Ask an open question to the chatters"
                }
            ]
        },
        "Spotify Song Announcement": {
            "notes": "Song Announcement",
            "description": "Song Announcement",
            "trigger": "spotify_new_song",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that ${song} is playing"
                }
            ]
        },
        "New Follower": {
            "notes": "New Follower",
            "description": "New Follower",
            "trigger": "twitch_channel_follow",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Thank ${username} for following the channel"
                }
            ]
        },
        "New Subscriber": {
            "notes": "New Subscriber",
            "description": "New Subscriber",
            "trigger": "twitch_channel_subscribe",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Thank ${username} for subscribing to the channel (tier ${tier})"
                }
            ]
        },
        "Resubscription": {
            "notes": "Resubscriber",
            "description": "Resubscriber",
            "trigger": "twitch_channel_subscription_message",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Thank ${username} for subscribing to the channel at tier ${tier} for ${cumulative} months (${streak} months streak). Answer user message: \"${message}\""
                }
            ]
        },
        "Subscription Gift": {
            "notes": "Gifter",
            "description": "Gifter",
            "trigger": "twitch_channel_subscription_gift",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Thank ${username} for donating ${total} tier ${tier} subscriptions to channel members. They have gifted ${cumulative} subscriptions in total on the channel"
                }
            ]
        },
        "Ad Break": {
            "notes": "Ad Break",
            "description": "Midroll Ad Start",
            "trigger": "twitch_ad_break_begin",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Ad is now playing, remind users that they can go ad-free if they subscribe"
                }
            ]
        },
        "Raid From Other Channel": {
            "notes": "Raid From Other Channel",
            "description": "Raid From Other Channel",
            "trigger": "twitch_raid_from",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that streamer ${raid_from} has raided the channel with ${raid_viewers} viewers"
                }
            ]
        },
        "Raid To Other Channel": {
            "notes": "Raid To Other Channel",
            "description": "Raid To Other Channel",
            "trigger": "twitch_raid_to",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that you are raiding ${raid_to} with ${raid_viewers} viewers, remind everyone to say hi to ${raid_to}"
                }
            ]
        },
        "Shoutout Created": {
            "notes": "Shoutout Created",
            "description": "Shoutout Create",
            "trigger": "twitch_shoutout_create",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that we are recognizing streamer @${username}"
                }
            ]
        },
        "Shoutout Received": {
            "notes": "Shoutout Received",
            "description": "Shoutout Receive",
            "trigger": "twitch_shoutout_receive",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Streamer @${username} has given a shoutout to this channel"
                }
            ]
        },
        "Channel Cheer": {
            "notes": "Channel Cheer",
            "description": "Channel Cheer",
            "trigger": "twitch_channel_cheer",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Thank ${username} for cheering with ${cheer_amount} bits, donation message reads: \"${message}\""
                }
            ]
        },
        "Hype Train Begin": {
            "notes": "Hype Train Begin",
            "description": "Hype Train Begin",
            "trigger": "twitch_hypetrain_begin",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that a level ${hype_level} hype train is now in progress, users can contribute to level up the train with bits and subscriptions."
                }
            ]
        },
        "Hype Train End": {
            "notes": "Hype Train End",
            "description": "Hype Train End",
            "trigger": "twitch_hypetrain_end",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that the hype train has ended at level ${hype_level}. The top contributors were users @${hype_top_bits_user} (bits) and @${hype_top_subs_user} (subscriptions). Ignore N/A users."
                }
            ]
        },
        "Channel Goal Reached": {
            "notes": "Channel Goal Reached",
            "description": "Channel Goal Reached",
            "trigger": "twitch_goal_reached",
            "actions": [
                {
                    "type": "Enhanced",
                    "message": "Announce that the channel goal to ${channel_goal_type} (${channel_goal_description}) has been reached at ${channel_goal_current}/${channel_goal_target}."
                }
            ]
        }
    });

      return [triggerPresets, setTriggerPresets];
};

export default useTriggerPresets;