import { useState } from 'react';

function useSystemVariables() {
  const [systemVariables, setSystemVariables] = useState([
    {
        title: 'Username',
        description: 'The username of the user triggering the command/trigger',
        copy: '${username}'
    },
    {
        title: 'Is Subscribed',
        description: 'Whether the user is subscribed ("true") or not ("false")',
        copy: '${is_subscribed}'
    },
    {
        title: 'Is Moderator',
        description: 'Whether the user is a moderator in the channel ("true") or not ("false")',
        copy: '${is_mod}'
    },
    {
        title: 'Full Message',
        description: 'The user\'s full message, including command if applicable',
        copy: '${message}'
    },
    {
        title: 'Trimmed Message',
        description: 'The user\'s message without the command',
        copy: '${params}'
    },
    {
        title: 'Current Song',
        description: 'The song currently playing on Spotify',
        copy: '${song}'
    },
    {
        title: 'Next Song',
        description: 'The upcoming song in the Spotify queue',
        copy: '${nextsong}'
    },
    {
        title: 'Song Details',
        description: 'Details about the song currently playing',
        copy: '${songdetails}'
    },
    {
        title: 'Song Requester',
        description: 'The user who requested the song currently playing',
        copy: '${songrequester}'
    },
    {
        title: 'Top Chatters',
        description: 'List of the most active chat users for the current stream',
        copy: '${topchatters}'
    },
    {
        title: 'Random Number',
        description: 'A random number between a given range, copy example is 1-100',
        copy: '${rng(1,100)}'
    },
    {
        title: 'Stream Category',
        description: 'The category or game of the stream',
        copy: '${category}'
    },
    {
        title: 'Stream Title',
        description: 'The title of the stream',
        copy: '${title}'
    },
    {
        title: 'Stream Uptime',
        description: 'How long the current stream has been ongoing (gets reset if bot is restarted)',
        copy: '${uptime}'
    },
    {
        title: 'Viewer Count',
        description: 'Number of current viewers',
        copy: '${numviewers}'
    },
    {
        title: 'Subscription Tier',
        description: 'To use in subscription/gift subscription/resubscription triggers: The tier of the subscription',
        copy: '${tier}'
    },
    {
        title: 'Subscriptions Cumulative',
        description: 'To use in resubscribe/gift subscription triggers: Cumulative subscription gifts from user on gift event or cumulative months on resubscribe event',
        copy: '${cumulative}'
    },
    {
        title: 'Subscription Streak',
        description: 'To use in resubscription triggers: Current subscription streak for user',
        copy: '${streak}'
    },
    {
        title: 'Subscription Gift Total',
        description: 'To use in gift subscription triggers: Total number of gifted subs on event (not all-time, use cumulative for this)',
        copy: '${total}'
    },
    {
        title: 'Midroll Ad Length',
        description: 'Length in seconds of the mid-roll ad break requested',
        copy: '${ad_length}',
    },
    {
        title: 'Raid Viewers',
        description: 'The number of viewers in the raid',
        copy: '${raid_viewers}',
    },
    {
        title: 'Raid To',
        description: 'The broadcaster display name that received the raid',
        copy: '${raid_to}',
    },
    {
        title: 'Raid From',
        description: 'The broadcaster display name that created the raid',
        copy: '${raid_from}',
    },
    {
        title: 'Cheer Amount',
        description: 'How many bits were cheered during a channel cheer event',
        copy: '${cheer_amount}',
    },
    {
        title: 'Hype Train Level',
        description: 'The level of the hype train event',
        copy: '${hype_level}',
    },
    {
        title: 'Hype Train Top Bits User',
        description: 'The username of the top bits contributor for the hype train event',
        copy: '${hype_top_bits_user}',
    },
    {
        title: 'Hype Train Top Subs User',
        description: 'The username of the top subscriptions contributor for the hype train event',
        copy: '${hype_top_subs_user}',
    },
    {
        title: 'Channel Goal Current',
        description: 'The current amount towards the channel goal',
        copy: '${channel_goal_current}',
    },
    {
        title: 'Channel Goal Target',
        description: 'The channel goal target amount',
        copy: '${channel_goal_target}',
    },
    {
        title: 'Channel Goal Type',
        description: 'The type of channel goal (new followers, new subscriptions etc.)',
        copy: '${channel_goal_type}',
    },
    {
        title: 'Channel Goal Description',
        description: 'Description of the current channel goal',
        copy: '${channel_goal_description}',
    }
  ]);

  return [systemVariables, setSystemVariables];
}

export default useSystemVariables;