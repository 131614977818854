import { Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { tokens } from '../../theme';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const Left = ({isMobile}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const twitchAuthUrl = `https://id.twitch.tv/oauth2/authorize?client_id=ume7g8iuurmh5omjn30bezogytqijm&redirect_uri=${apiUrl}/callback/twitch&response_type=code&scope=user%3Aread%3Aemail`;
    const editorAuthUrl = `https://id.twitch.tv/oauth2/authorize?client_id=ume7g8iuurmh5omjn30bezogytqijm&redirect_uri=${apiUrl}/callback/twitcheditor&response_type=code&scope=user%3Aread%3Aemail`;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const styles = {
      text: {
        color: `${colors.primary[900]}`,
        fontSize: '1.25rem',
        fontWeight: 'bold',
        textShadow: `0px 0px 10px ${colors.purpleAccent[400]}`,
        textTransform: 'none'
      },
    };

    const MainHeader = () => {
      const greenTextColor = `rgba(46, 153, 69, 1)`;
      return (
        <Typography variant={isMobile ? 'h5' : 'h3'} color="primary" style={{ display: 'inline-block', textAlign: 'center' }}>
          <span style={{ color: `${greenTextColor}` }}>Ratbot</span>
          <span style={{ color: colors.primary['900'] }}>&nbsp;the&nbsp;</span>
          <span style={{ color: `${greenTextColor}` }}>Chatbot</span>
          <span style={{ color: colors.primary['900'] }}>&nbsp;on&nbsp;</span>
          <span style={{ color: colors.purpleAccent['800'] }}>Twitch</span>
        </Typography>
      );
    };
    const greenTextColor = `rgba(46, 153, 69, 1)`;
    return (
      <Container fixed sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '0' }}>
          <img src="https://i.imgur.com/VQFZdA4.png" width="300px" alt="Ratbot logo" />
          <MainHeader />
          <br />
          <Typography variant="h5" gutterBottom style={{ textAlign: 'center', color:`${colors.purpleAccent['800']}` }}>
            <span style={{ color: colors.primary['900'] }}>Personality-filled conversational&nbsp;</span>
            <span style={{ color: `${greenTextColor}` }}>AI-powered&nbsp;</span>
            <span style={{ color: colors.primary['900'] }}>chatbot for&nbsp;</span>
            <span>Twitch</span>
          </Typography>
          <Button href={twitchAuthUrl} startIcon={<img  src="https://i.imgur.com/fFUbVgg.png" width="20px" alt="Twitch logo" />} 
                  variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: `${colors.purpleAccent['400']}`, '&:hover': { backgroundColor: `${colors.purpleAccent['600']}`} }}>
            <Typography variantMapping={{subtitle1: 'span'}} sx={styles.text} variant='h6'>Sign in with Twitch</Typography>
          </Button>
          {/*<Button href={editorAuthUrl} startIcon={<img  src="https://i.imgur.com/fFUbVgg.png" width="20px" alt="Twitch logo" />}
                  variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: `${colors.blueAccent['200']}`, '&:hover': { backgroundColor: `${colors.blueAccent['400']}`} }}>
            <Typography variantMapping={{subtitle1: 'span'}} sx={styles.text} variant='h6'>Editor Login</Typography>
          </Button>*/}
          {/*<Button onClick={() => navigate(`/about?p=about`)}>
            About Ratbot
          </Button>*/}
      </Container>
    );
};

export default Left;