import { useCallback } from 'react';
import { useUser } from '../context/UserContext';

const useCopyArray = () => {
    const authCtx = useUser();
    const getCopyArray = useCallback((code, datatype, unique = false) => {
        switch (datatype) {
            case 'number':
                if (unique) return [
                    `${'${'}${code}.read}`,
                    `${'${'}${code}.increment.1}`,
                    `${'${'}${code}.decrement.1}`,
                    `${'${'}${code}.set.0}`,
                    `${'${'}${authCtx.username}.${code}.read}`,
                    `${'${'}${authCtx.username}.${code}.increment.1}`,
                    `${'${'}${authCtx.username}.${code}.decrement.1}`,
                    `${'${'}${authCtx.username}.${code}.set.0}`,
                    `${'${'}all-users.${code}.increment.1}`,
                    `${'${'}all-users.${code}.decrement.1}`,
                    `${'${'}all-users.${code}.set.0}`
                ];
                return [
                    `${'${'}${code}.read}`,
                    `${'${'}${code}.increment.1}`,
                    `${'${'}${code}.decrement.1}`,
                    `${'${'}${code}.set.0}`
                ];
            case 'text':
                if (unique) return [
                    `${'${'}${code}.read}`,
                    `${'${'}${code}.set.New text here}`,
                    `${'${'}${authCtx.username}.${code}.read}`,
                    `${'${'}${authCtx.username}.${code}.set.New text here}`,
                    `${'${'}all-users.${code}.set.New text here}`
                ];
                return [
                    `${'${'}${code}.read}`,
                    `${'${'}${code}.set.New text here}`
                ];
            case 'boolean':
                if (unique) return [
                    `${'${'}${code}.read}`,
                    `${'${'}${code}.toggle}`,
                    `${'${'}${code}.set.true}`,
                    `${'${'}${authCtx.username}.${code}.read}`,
                    `${'${'}${authCtx.username}.${code}.toggle}`,
                    `${'${'}${authCtx.username}.${code}.set.true}`,
                    `${'${'}all-users.${code}.toggle}`,
                    `${'${'}all-users.${code}.set.true}`
                ];
                return [
                    `${'${'}${code}.read}`,
                    `${'${'}${code}.toggle}`,
                    `${'${'}${code}.set.true}`
                ];
            case 'list':
                if (unique) return [
                    `${'${'}${code}.read.0}`,
                    `${'${'}${code}.length}`,
                    `${'${'}${code}.add.New value here}`,
                    `${'${'}${code}.set.0.New value here}`,
                    `${'${'}${code}.delete.0}`,
                    `${'${'}${code}.includes.Value here}`,
                    `${'${'}${code}.getposition.Value here}`,
                    `${'${'}${code}.clear}`,
                    `${'${'}${authCtx.username}.${code}.read.0}`,
                    `${'${'}${authCtx.username}.${code}.length}`,
                    `${'${'}${authCtx.username}.${code}.add.New value here}`,
                    `${'${'}${authCtx.username}.${code}.set.0.New value here}`,
                    `${'${'}${authCtx.username}.${code}.delete.0}`,
                    `${'${'}${authCtx.username}.${code}.includes.Value here}`,
                    `${'${'}${authCtx.username}.${code}.getposition.Value here}`,
                    `${'${'}${authCtx.username}.${code}.clear}`,
                    `${'${'}all-users.${code}.add.New value here}`,
                    `${'${'}all-users.${code}.set.0.New value here}`,
                    `${'${'}all-users.${code}.delete.0}`,
                    `${'${'}all-users.${code}.includes.Value here}`,
                    `${'${'}all-users.${code}.clear}`
                ];
                return [
                    `${'${'}${code}.read.0}`,
                    `${'${'}${code}.length}`,
                    `${'${'}${code}.add.New value here}`,
                    `${'${'}${code}.set.0.New value here}`,
                    `${'${'}${code}.delete.0}`,
                    `${'${'}${code}.includes.Value here}`,
                    `${'${'}${code}.getposition.Value here}`,
                    `${'${'}${code}.clear}`
                ];

            default:
                return [`${'${'}${code}.read}`];
        }
    }, []);

    return { getCopyArray };
};

export default useCopyArray;