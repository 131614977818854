import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useUserSettings } from '../../store/usersettings-context';
import { AnimatePresence, motion } from 'framer-motion';
import DialogComponent from '../layouts/DialogComponent';
import { Divider } from 'primereact/divider';

const IgnoredUsers = ({backgroundColor = '#5D3B9E'}) => {
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const [newUser, setNewUser] = useState('');
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none !important' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none !important', // For Internet Explorer and Edge
        'scrollbar-width': 'none !important' // For Firefox
    };
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    const addHandler = () => {
        update({blockedUsers: [...userSettings.blockedUsers, newUser]}).then(() => {
            setNewUser('');
        });
    };

    const deleteHandler = (alias) => {
        const aliases = [...userSettings.blockedUsers].filter((a) => a !== alias);
        update({blockedUsers: aliases});
    };

    return (
        <DialogComponent footer='List of users that Ratbot will ignore commands and messages from.' maxWidth='25vw' minWidth='25vw' maxHeight='75vh'>
        <Grid container spacing={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '16px', paddingRight: '16px', backgroundColor: colorScheme.background25}}>
            <Grid item xs={2}>
                <motion.div initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} transition={{duration: 0.3, delay: 0.1}}>
                    <Button icon='pi pi-plus' severity='success' size='small' onClick={addHandler} style={{marginLeft: '8px', marginBottom: '4px'}} />
                </motion.div>
            </Grid>
            <Grid item xs={10}>
                <motion.div initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} transition={{duration: 0.3, delay: 0.1}}>
                    <InputText style={{backgroundColor, width: '100%'}} value={newUser} onChange={(e) => setNewUser(e.target.value)} />
                </motion.div>
            </Grid>
            <Grid item xs={12} sx={{marginTop: '16px', maxHeight: '50vh', overflowY: 'auto', ...hideScrollbar, backgroundColor: colorScheme.background25}}>
                <AnimatePresence>
                    {userSettings.blockedUsers.map((a, i) => (
                        <motion.div key={a} initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, x: 20}} transition={{duration: 0.3, delay: 0.1}}>
                            <Grid container spacing={2} paddingBottom='8px'>
                                <Grid item xs={2}>
                                    <Button severity='danger' icon='pi pi-minus' onClick={() => deleteHandler(a)} size='small' style={{marginLeft: '8px', marginBottom: '4px'}} />
                                </Grid>
                                <Grid item xs={10} sx={{ marginTop: '4px' }}>
                                    <h3 style={{margin: '0px', padding: '0px', color: 'var(--complementary-color)'}}>{a.toUpperCase()}</h3>
                                </Grid>
                            </Grid>
                            {i !== (userSettings.blockedUsers.length - 1) && <Divider />}
                        </motion.div>
                    ))}
                </AnimatePresence>
            </Grid>
        </Grid>
        </DialogComponent>
    );
};

export default IgnoredUsers;