import React from 'react';
import { Menubar } from 'primereact/menubar';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import 'primeicons/primeicons.css';
import useLatestStreams from '../../utils/useLatestStreams';
import useStreamers from '../../utils/useStreamers';
import useIsSubscribed from '../../utils/useIsSubscribed';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { useUser } from '../../context/UserContext';
import './Topbar.css';
import ColorPickerButton from './ColorPickerButton';
import { Button } from 'primereact/button';

const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Format timestamp as a string
};

const Topbar = ({backgroundColor = '#5D3B9E', complementaryColor = '#FFFFFF', setBackgroundColor = () => {}, setIsBlur = () => {}, openDialogComponent = () => {}, 
                    setMainViewComponent = () => {}, setMainViewHeader = () => {}, userSettings = {}, update = () => {}}) => {
    const isSmall = useWindowSizeThreshold(540, true);
    const isMedium = useWindowSizeThreshold(1040, true);
    const isMobile = useIsMobile();
    const authCtx = useUser();
    const { filenames, loading } = useLatestStreams();
    const streamers = useStreamers();
    const isSubscribed = useIsSubscribed();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const styles = {
        title: {
            position: 'absolute',
            top: isSmall ? '5px' : '0px',
            left: isSmall ? '35vw' : '25vw',
            fontSize: isSmall ? '1.25rem' : '1.5rem',
            fontWeight: 700,
            textShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
            letterSpacing: '0.1em',
            background: '-webkit-linear-gradient(45deg, #9d65ff, #8461e8)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginBottom: '1rem',
            userSelect: 'none'
        },
        topbarStyle: {
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 999,
            opacity: '1',
            background: colorScheme.background25,
            '--background50-color': colorScheme.background50,
        },
        afterElementStyle: {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: isMobile ? '0px' : '200px', // Conditional left offset
            width: isMobile ? '100%' : 'calc(100% - 200px)', // Conditional width
            borderBottom: `2px solid var(--background50-color)`,
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)', // Box shadow
        }
    };
    const MenuItemIcon = ({icon, onClick = () => {}}) => {
        return (
            <div onClick={onClick} style={{marginTop: '6px', marginRight: '8px'}}>
                {icon}
            </div>
        );
    };
    const getBotRunningStatus = () => {
        if (Object.keys(streamers).includes(authCtx.username)) {
            return 'Online';
        } else {
            return 'Offline';
        }
    };
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };
    const MenuLabel = ({label, level}) => {
        const Tag = level === 'sub' ? 'h5' : 'h4'; // Use h5 for subitems, h4 for top-level items
        return (
            <Tag style={{ margin: '0px', padding: '0px' }}>
                {label}
            </Tag>
        );
    };

    const items = [
        // USER
        (authCtx.editor === false) ? {
            label: <MenuLabel label={authCtx.username} level='h4' />,
            icon: <MenuItemIcon icon={<AccountCircleOutlinedIcon />} />,
            items: [
                {
                    label: <MenuLabel label={`Subscription: ${isSubscribed ? 'Active' : 'Inactive'}`} level='sub' />,
                    icon: `pi pi-fw ${isSubscribed ? 'pi-circle-fill' : 'pi-circle'}`,
                },
                {
                    label: <MenuLabel label={`Bot Status: ${getBotRunningStatus()}`} level='sub' />,
                    icon: `pi pi-fw ${getBotRunningStatus() === 'Offline' ? 'pi-circle' : 'pi-circle-fill'}`,
                },
                {
                    label: <MenuLabel label={'Stream Stats'} level='sub' />,
                    icon: 'pi pi-fw pi-chart-line',
                    command: () => openDialogComponent('streamstats'),
                    items: filenames ? Object.keys(filenames).map((timestamp) => ({
                        label: <MenuLabel label={formatTimestamp(filenames[timestamp]['startedAt'])} level='sub' />,
                        command: () => {
                            openDialogComponent('streamstats', formatTimestamp(filenames[timestamp]['startedAt']));
                        }
                    })) : []
                },
                {
                    label: <MenuLabel label={'Chatlogs'} level='sub' />,
                    icon: 'pi pi-fw pi-envelope',
                    command: () => openDialogComponent('chatlogs')
                },
                {
                    label: <MenuLabel label={'Editors'} level='sub' />,
                    icon: 'pi pi-fw pi-user',
                    command: () => openDialogComponent('editors')
                }
            ]
        } : null,
        // GENERAL
        {
            label: <MenuLabel label='General' level='h4' />,
            icon: <MenuItemIcon icon={<SettingsOutlinedIcon />} />,
            items: [
                ('botAliases' in userSettings) ? {
                    label: <MenuLabel label={'Aliases'} level='sub' />,
                    items: userSettings?.botAliases.length > 0 ? 
                    [
                    ...userSettings.botAliases.map((a) => ({
                        label: <MenuLabel label={a.toUpperCase()} level='sub' />,
                        command: () => openDialogComponent('aliases')
                    }))]
                    : [{label: <MenuLabel label={'No aliases'} level='sub' />, command: () => openDialogComponent('aliases')}]
                } : null,
                ('blockedUsers' in userSettings) ? {
                    label: <MenuLabel label={'Ignored Users'} level='sub' />,
                    items: userSettings?.blockedUsers.length > 0 ? userSettings.blockedUsers.map((b) => ({
                        label: <MenuLabel label={b.toUpperCase()} level='sub' />,
                        command: () => openDialogComponent('ignoredusers')
                    })) : [{label: <MenuLabel label={'No users'} level='sub' />, command: () => openDialogComponent('ignoredusers')}]
                } : null,
                // Separator between 'Aliases'/'Ignored Users' and 'Exit Message'/'Context-Aware Tuning'
                (('botAliases' in userSettings) || ('blockedUsers' in userSettings)) ? {
                    separator: true
                } : null,
                ('exitMessage' in userSettings) ? {
                    label: <MenuLabel label={'Exit Message'} level='sub' />,
                    command: () => openDialogComponent('exitmessage')
                } : null,
                ('openAISettings' in userSettings) ? (
                    ('customPrompt' in userSettings['openAISettings']) ? {
                        label: <MenuLabel label={'Context-Aware Tuning'} level='sub' />,
                        command: () => openDialogComponent('usercontext')
                    } : null
                ) : null,
                // Separator between 'Exit Message'/'Context-Aware Tuning' and 'Buffer Timer'
                (('exitMessage' in userSettings) || ('openAISettings' in userSettings && 'customPrompt' in userSettings['openAISettings'])) ? {
                    separator: true
                } : null,
                ('messageInterval' in userSettings) ? {
                    label: <MenuLabel label={`Buffer Timer (${parseInt(userSettings.messageInterval)/1000} sec)`} level='sub' />,
                    command: () => openDialogComponent('buffertimer')
                } : null,
                // Separator before 'Emotes'
                ('messageInterval' in userSettings) ? {
                    separator: true
                } : null,
                ('useEmotes' in userSettings || 'emoteFrequency' in userSettings) ? {
                    label: <MenuLabel label={'Emotes'} level='sub' />,
                    items: [
                        ('useEmotes' in userSettings) ? {
                            label: <MenuLabel label={'Use Emotes'} level='sub' />,
                            command: () => update({useEmotes: !userSettings.useEmotes}),
                            icon: userSettings.useEmotes && 'pi pi-fw pi-check'
                        } : null,
                        ('emoteFrequency' in userSettings) ? {
                            label: <MenuLabel label={'Adjust Frequency'} level='sub' />,
                            command: () => openDialogComponent('emotefrequency')
                        } : null
                    ].filter(Boolean)
                } : null,
                // Separator before 'Announce Personality Switch'/'Short Replies'/'User Profiling'
                ('openAISettings' in userSettings) ? {
                    separator: true
                } : null,
                ('openAISettings' in userSettings) && (
                    ('announceSwitch' in userSettings['openAISettings']) ? {
                        label: <MenuLabel label={'Announce Personality Switch'} level='sub' />,
                        icon: userSettings.openAISettings.announceSwitch && 'pi pi-fw pi-check',
                        command: () => update({openAISettings: {...userSettings.openAISettings, announceSwitch: !userSettings.openAISettings.announceSwitch}})
                } : null),
                ('openAISettings' in userSettings) && (
                    ('shortReplies' in userSettings['openAISettings']) ? {
                        label: <MenuLabel label={'Short Replies'} level='sub' />,
                        icon: userSettings.openAISettings.shortReplies && 'pi pi-fw pi-check',
                        command: () => update({openAISettings: {...userSettings.openAISettings, shortReplies: !userSettings.openAISettings.shortReplies}})
                } : null),
                ('openAISettings' in userSettings) && (
                    ('profiling' in userSettings['openAISettings']) ? {
                        label: <MenuLabel label={'User Profiling'} level='sub' />,
                        icon: userSettings.openAISettings.profiling && 'pi pi-fw pi-check',
                        command: () => update({openAISettings: {...userSettings.openAISettings, profiling: !userSettings.openAISettings.profiling}})
                } : null)
            ].filter(Boolean)
        },
        // COMMANDS
        {
            label: <MenuLabel label='Commands' level='h4' />,
            icon: <MenuItemIcon icon={<CodeOutlinedIcon />} />,
            items: [
                ('commandDelimiter' in userSettings) ? {
                    label: <MenuLabel label={'Command Identifier'} level='sub' />,
                    items: [
                        {
                            label: <MenuLabel label={'!command'} level='sub' />,
                            icon: userSettings.commandDelimiter === '!' && 'pi pi-fw pi-check',
                            command: () => update({commandDelimiter: '!'})
                        },
                        {
                            label: <MenuLabel label={'#command'} level='sub' />,
                            icon: userSettings.commandDelimiter === '#' && 'pi pi-fw pi-check',
                            command: () => update({commandDelimiter: '#'})
                        },
                        {
                            label: <MenuLabel label={'$command'} level='sub' />,
                            icon: userSettings.commandDelimiter === '$' && 'pi pi-fw pi-check',
                            command: () => update({commandDelimiter: '$'})
                        }
                    ]
                } : null,
                ('adminLevel' in userSettings) ? {
                    label: <MenuLabel label={'Mod Commands'} level='sub' />,
                    items: [
                        {
                            label: <MenuLabel label={'Broadcaster'} level='sub' />,
                            icon: userSettings.adminLevel === 'broadcaster' && 'pi pi-fw pi-check',
                            command: () => update({adminLevel: 'broadcaster'})
                        },
                        {
                            label: <MenuLabel label={'Broadcaster and moderators'} level='sub' />,
                            icon: userSettings.adminLevel === 'moderator' && 'pi pi-fw pi-check',
                            command: () => update({adminLevel: 'moderator'})
                        },
                        {
                            label: <MenuLabel label={'Broadcaster, moderators and VIPs'} level='sub' />,
                            icon: userSettings.adminLevel === 'vip' && 'pi pi-fw pi-check',
                            command: () => update({adminLevel: 'vip'})
                        }
                    ]
                } : null,
                ('disabledCommands' in userSettings) ? {
                    label: <MenuLabel label={'System Commands'} level='sub' />,
                    command: () => openDialogComponent('systemcommands')
                } : null
            ].filter(Boolean)
        },
        // INTEGRATIONS
        {
            label: <MenuLabel label='Integrations' level='h4' />,
            icon: <MenuItemIcon icon={<IntegrationInstructionsIcon />} />,
            items: [
                ('modData' in userSettings) ? (
                    ('twitch' in userSettings['modData']) ? {
                        label: <MenuLabel label={'Twitch'} level='sub' />,
                        icon: 'pi pi-video',
                        items: ((userSettings['modData'] && userSettings['modData']['twitch'] && userSettings['modData']['twitch']['refresh_token']) || authCtx.editor) ? [
                            {
                                label: <MenuLabel label={'Viewer Rewards'} level='sub' />,
                                items: [
                                    {
                                        label: <MenuLabel label={'Personality Redeems'} level='sub' />,
                                        command: () => openDialogComponent('twitchpersonalityrewards')
                                    }
                                ]
                            },
                            {
                                label: <MenuLabel label={'Polls'} level='sub' />,
                                items: [
                                    {
                                        label: <MenuLabel label={`Poll Length: ${userSettings?.modData?.twitch?.commands?.create_poll?.duration !== undefined ? formatTime(userSettings.modData.twitch.commands.create_poll.duration) : formatTime(60)}`} level='sub' />,
                                        command: () => openDialogComponent('twitchpolllength')
                                    },
                                    {
                                        label: <MenuLabel label={'Poll Chat Command'} level='sub' />,
                                        command: () => openDialogComponent('twitchpollcommand')
                                    }
                                ]
                            },
                            {
                                label: <MenuLabel label={'Predictions'} level='sub' />,
                                items: [
                                    {
                                        label: <MenuLabel label={`Prediction Window: ${userSettings?.modData?.twitch?.commands?.create_prediction?.prediction_window !== undefined ? formatTime(userSettings.modData.twitch.commands.create_prediction.prediction_window) : formatTime(60)}`} level='sub' />,
                                        command: () => openDialogComponent('twitchpredictionlength')
                                    },
                                    {
                                        label: <MenuLabel label={'Prediction Chat Command'} level='sub' />,
                                        command: () => openDialogComponent('twitchpredictioncommand')
                                    },
                                    {
                                        label: <MenuLabel label={'End Prediction Chat Command'} level='sub' />,
                                        command: () => openDialogComponent('twitchendpredictioncommand')
                                    }
                                ]
                            }
                        ] : [
                            {
                                label: <MenuLabel label={'Authorization needed'} level='sub' />
                            }
                        ]
                    } : null
                ) : null,
                ('spotify' in userSettings) ? {
                    label: <MenuLabel label={'Spotify'} level='sub' />,
                    icon: 'pi pi-headphones',
                    items: ((userSettings['spotify'] && userSettings['spotify']['refreshToken']) || authCtx.editor) ? [
                        {
                            label: <MenuLabel label={'Announce New Song'} level='sub' />,
                            items: [
                                {
                                    label: <MenuLabel label={'Requested Songs'} level='sub' />,
                                    icon: userSettings.spotify.nowPlayingRequested && 'pi pi-fw pi-check',
                                    command: () => update({spotify: {...userSettings.spotify, nowPlayingRequested: !userSettings.spotify.nowPlayingRequested}})
                                },
                                {
                                    label: <MenuLabel label={'All Songs'} level='sub' />,
                                    icon: userSettings.spotify.nowPlayingAll && 'pi pi-fw pi-check',
                                    command: () => update({spotify: {...userSettings.spotify, nowPlayingAll: !userSettings.spotify.nowPlayingAll}})
                                }
                            ]
                        },
                        {
                            label: <MenuLabel label={'Restrictions'} level='sub' />,
                            items: [
                                {
                                    label: <MenuLabel label={'Allow Explicit Lyrics'} level='sub' />,
                                    icon: userSettings.spotify.allowExplicit && 'pi pi-fw pi-check',
                                    command: () => update({spotify: {...userSettings.spotify, allowExplicit: !userSettings.spotify.allowExplicit}})
                                },
                                {
                                    label: <MenuLabel label={'Track Length'} level='sub' />,
                                    items: [
                                        {
                                            label: <MenuLabel label={'Minimum Length'} level='sub' />,
                                            command: () => openDialogComponent('spotifymintracklength')
                                        },
                                        {
                                            label: <MenuLabel label={'Maximum Length'} level='sub' />,
                                            command: () => openDialogComponent('spotifymaxtracklength')
                                        }
                                    ]
                                },
                                {
                                    label: <MenuLabel label={'Blocked Tracks'} level='sub' />,
                                    items: userSettings.spotify.blockedTracks.length > 0 ? (userSettings.spotify.blockedTracks.map((o, i) => (
                                        {
                                            label: (<MenuLabel label={`${o['name']} by ${o['artist']}`} level='sub' key={i} />),
                                            command: () => openDialogComponent('spotifyBlockedTracks')
                                        }
                                    ))) : [
                                        {
                                            label: <MenuLabel label={'No blocked tracks'} level='sub' />,
                                            command: () => openDialogComponent('spotifyBlockedTracks')
                                        }
                                    ]
                                },
                                {
                                    label: <MenuLabel label={'Blocked Artists'} level='sub' />,
                                    items: userSettings.spotify.blockedArtists.length > 0 ? (userSettings.spotify.blockedArtists.map((o, i) => (
                                        {
                                            label: (<MenuLabel label={`${o['name']}`} level='sub' key={i} />),
                                            command: () => openDialogComponent('spotifyBlockedArtists')
                                        }
                                    ))) : [
                                        {
                                            label: <MenuLabel label={'No blocked artists'} level='sub' />,
                                            command: () => openDialogComponent('spotifyBlockedArtists')
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: <MenuLabel label={'Viewer Rewards'} level='sub' />,
                            items: !!userSettings.spotify.rewards.songRequest.id !== '' ? [
                                {
                                    label: <MenuLabel label={'Song request reward enabled'} level='sub' />,
                                    icon: 'pi pi-fw pi-check'
                                }
                            ] : [
                                {
                                    label: <MenuLabel label={'Song request reward not enabled'} level='sub' />
                                }
                            ]
                        },
                        {
                            separator: true
                        },
                        {
                            label: <MenuLabel label={'Start Playing On Redeem'} level='sub' />,
                            // Check if `autoStart` exists before evaluating its truthiness
                            icon: userSettings.spotify?.autoStart ? 'pi pi-fw pi-check' : '',
                            command: () => update({spotify: {...userSettings.spotify, autoStart: !userSettings.spotify.autoStart}})
                        },
                        {
                            label: <MenuLabel label={'Song Analysis'} level='sub' />,
                            // Check if `analysis` exists in `modData` before evaluating its truthiness
                            icon: userSettings.spotify?.analysis ? 'pi pi-fw pi-check' : '',
                            command: () => update({spotify: {...userSettings.spotify, analysis: !userSettings.spotify.analysis}})
                        },
                        {
                            separator: true
                        },
                        {
                            label: <MenuLabel label={'Track Added Message'} level='sub' />,
                            command: () => openDialogComponent('spotifytrackconfirmationmsg')
                        }
                    ] : [
                        {
                            label: <MenuLabel label={'Authorization needed'} level='sub' />
                        }
                    ]
                } : null
            ].filter(Boolean)
        },
        // ABOUT
        {
            label: <MenuLabel label='About' level='h4' />,
            icon: <MenuItemIcon icon={<InfoOutlinedIcon />} />,
            items: [
                {
                    label: <MenuLabel label={'FAQ'} level='sub' />,
                    icon: 'pi pi-question-circle',
                    command: () => openDialogComponent('faq')
                },
                {
                    label: <MenuLabel label={'Discord'} level='sub' />,
                    icon: 'pi pi-discord',
                    command: () => window.open('https://discord.gg/HJv5d7bEea', '_blank')
                },
                {
                    label: <MenuLabel label={'Redeem Code'} level='sub' />,
                    icon: 'pi pi-bolt',
                    command: () => openDialogComponent('redeemcode')
                }
            ].filter(Boolean)
        }
    ].filter(Boolean);

    const start = (
        <div onClick={() => setMainViewComponent('dashboard')} style={{ cursor: 'pointer' }}>
            <img alt="logo" src={authCtx.profile_url} height="50" width="50" style={{ marginTop: '6px', marginLeft: '16px', marginRight: '16px', borderRadius: '25px' }} />
        </div>
    );

    const end = (
        <React.Fragment>
            {authCtx.isAdmin && !isMobile && (
                <Button label='Admin' variant='text' size='small' color='info' endIcon={<LockOpenIcon />} style={{marginRight: '16px'}} onClick={() => {
                    setMainViewComponent('admin');
                    setMainViewHeader('Admin');
                }} />
            )}
            <ColorPickerButton backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} />
            <Button style={{width: '128px'}} iconPos='right' icon='pi pi-sign-out' size='small' onClick={authCtx.logout} label={isMedium ? '' : 'Log Out'} />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Menubar model={items} start={start} end={end} style={styles.topbarStyle} />
            {isMobile && <p style={styles.title}>{authCtx.username}</p>}
        </React.Fragment>
    );
};

export default Topbar;