import React, { useEffect, useRef, useState } from 'react';
import { useUser } from '../../context/UserContext';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import MainView from '../views/MainView';
import { InputText } from 'primereact/inputtext';
import { Grid } from '@mui/material';
import { Message } from 'primereact/message';
import { TabMenu } from 'primereact/tabmenu';
import { useLocation, useNavigate } from 'react-router-dom';
import './spotify.css';
import ConnectionSettings from './ConnectionSettings';
import Filters from './Filters';
import ChatGeneralSettings from './ChatGeneral';
import RequestQueue from './RequestQueue';

const SpotifyMain = ({openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, refresh = () => {}}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const isMobile = useIsMobile();
    const isLarge = useWindowSizeThreshold(1280, true);
    const stepperRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [requestEmail, setRequestEmail] = useState('');
    const [requestError, setRequestError] = useState('');
    const [requestSuccess, setRequestSuccess] = useState('');
    const [checkStatus, setCheckStatus] = useState('Not found');
    const [hasAccess, setHasAccess] = useState(false);
    const [isAutoCheck, setIsAutoCheck] = useState(true);
    const [spotifyAuthUrl, setSpotifyAuthUrl] = useState('#');
    const [activeTab, setActiveTab] = useState(0);
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.25)`
    };
    const styles = {
        stepper: {
            width: '100%', 
            backgroundColor: colorScheme.background75, 
            borderRadius: '15px'
        },
        stepperPanel: {
            backgroundColor: colorScheme.background75
        },
        stepperPanelHeader: {
            color: 'var(--complementary-color)'
        },
        stepperContentPanel: {
            backgroundColor: colorScheme.background75
        },
        contentTitle: {
            color: 'var(--complementary-color)'
        },
        contentText: {
            color: 'var(--complementary-color)'
        },
        inputText: {
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            color: 'var(--complementary-color)'
        },
        tabMenu: {
            backgroundColor: 'transparent',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        },
        tabMenuItem: {
            flexGrow: 1,
            textAlign: 'center'
        }
    };
    const tabItems = [
        { label: 'Connection Settings', icon: 'pi pi-wave-pulse' },
        { label: 'Filters', icon: 'pi pi-filter' },
        { label: 'Chat & General', icon: 'pi pi-wrench' },
        { label: 'Request Queue', icon: 'pi pi-headphones' }
    ];
    const tabPages = {
        0: <ConnectionSettings backgroundColor={backgroundColor} userSettings={userSettings} update={update} openDialogComponent={openDialogComponent} 
                setIsBlur={setIsBlur} refresh={refresh} />,
        1: <Filters backgroundColor={backgroundColor} userSettings={userSettings} update={update} openDialogComponent={openDialogComponent} 
                setIsBlur={setIsBlur} refresh={refresh} />,
        2: <ChatGeneralSettings backgroundColor={backgroundColor} userSettings={userSettings} update={update} openDialogComponent={openDialogComponent} 
                setIsBlur={setIsBlur} refresh={refresh} />,
        3: <RequestQueue backgroundColor={backgroundColor} userSettings={userSettings} update={update} openDialogComponent={openDialogComponent} 
                setIsBlur={setIsBlur} refresh={refresh} />
    };
    const StepperHeader = ({title = ''}) => {
        return (
            <h3 style={styles.stepperPanelHeader}>{title}</h3>
        );
    };

    const requestSpotifyAccess = async () => {
        try {
            setLoading(true);
            setRequestError('');
            setRequestSuccess('');
            const response = await fetch(`${apiUrl}/user/requestspotifyaccess?token=${authCtx.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email: requestEmail})
            });
            const data = await response.json();
            if (response.ok) {
                setRequestSuccess(data.status);
                setLoading(false);
            } else {
                setRequestError(data.error);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setRequestError(error.message);
            setLoading(false);
        }
    };
    const checkSpotifyAccess = async () => {
        try {
            setLoading(true);
            setCheckStatus('');
            const response = await fetch(`${apiUrl}/user/checkspotifyaccess?token=${authCtx.token}`);
            const data = await response.json();
            if (response.ok) {
                setHasAccess(true);
                setLoading(false);
            } else {
                if (!isAutoCheck) {
                    setCheckStatus(data.error);
                } else {
                    setIsAutoCheck(false);
                }
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            if (!isAutoCheck) {
                setCheckStatus(error.message);
            } else {
                setIsAutoCheck(false);
            }
            setLoading(false);
        }
    };
    const getSpotifyAuthUrl = async () => {
        try {
            const url = await fetch(`${apiUrl}/spotify/authurl?state=${authCtx.username}`);
            const data = await url.json();
            setSpotifyAuthUrl(data['status']);
        } catch (e) {
            console.log(e);
        }
    };
    const hasAuthorized = () => { return userSettings.spotify.refreshToken !== '' };

    useEffect(() => {
        // Get tab from query parameter on initial load
        const queryParams = new URLSearchParams(location.search);
        const tabParam = parseInt(queryParams.get('tab'), 10);
        
        if (!isNaN(tabParam) && tabParam >= 0 && tabParam < tabItems.length) {
            setActiveTab(tabParam);
        }
    }, []); // Only runs once on mount

    useEffect(() => {
        checkSpotifyAccess();
        getSpotifyAuthUrl();
    }, []);

    const handleTabChange = (e) => {
        setActiveTab(e.index);
        // Update query parameter to reflect current tab, preserving "p" query param if present
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('tab', e.index);
        navigate(`?${queryParams.toString()}`, { replace: true });
    };

    return (
        <MainView>
            {hasAuthorized() ? (
                <React.Fragment>
                    <TabMenu className='custom-tabmenu' model={tabItems} activeIndex={activeTab} onTabChange={handleTabChange} style={styles.tabMenu} />
                    {tabPages[activeTab]}
                </React.Fragment>
            ) : (
                <Stepper ref={stepperRef} style={styles.stepper} linear>
                    <StepperPanel header={<StepperHeader title='Introduction' />} style={styles.stepperPanel}>
                        <div style={styles.stepperContentPanel}>
                            <h1 style={styles.contentTitle}>Ratbot 💜 Spotify</h1>
                            <p style={styles.contentText}>
                                Spotify integration allows users to request songs and have them automatically added to the Spotify playqueue.
                                This lets you operate with a hands-off approach and minimal manual management during your stream. 
                            </p>
                            <h3 style={styles.contentTitle}>
                                Full Control
                            </h3>
                            <p style={styles.contentText}>
                                The online dashboard lets you set up song requests exactly how you want it; 
                                how users request songs, how the bot presents information, handling channel points rewards and more.
                            </p>
                            <p style={styles.contentText}>
                                Get started using Spotify and see for yourself why this is the most popular feature of Ratbot!
                            </p>
                            <br />
                            <Button style={{color: 'black'}} label='Continue' severity='success' icon='pi pi-arrow-right' iconPos='right' 
                                onClick={() => stepperRef.current.nextCallback()} />
                        </div>
                    </StepperPanel>
                    <StepperPanel header={<StepperHeader title='Request Access' />} style={styles.stepperPanel}>
                        <div style={styles.stepperContentPanel}>
                            <h1 style={styles.contentTitle}>Join the Fun</h1>
                            <p style={styles.contentText}>
                                The Ratbot app on Spotify unfortunately is not eligible for extended API access. 
                                This is because Ratbot integrates other services (like Twitch). 
                            </p>
                            {hasAccess ? (
                                <React.Fragment>
                                    <h3 style={styles.contentTitle}>You have received access!</h3>
                                    <Button style={{color: 'black'}} label='Continue' severity='success' icon='pi pi-arrow-right' iconPos='right' 
                                        onClick={() => stepperRef.current.nextCallback()} />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <br />
                                    <h3 style={styles.contentTitle}>What this means for you</h3>
                                    <p style={styles.contentText}>
                                        First, don't worry! You can still use Spotify with Ratbot. 
                                        However, we need to do some manual updates in the background to make sure it will work for you. 
                                    </p>
                                    <p style={styles.contentText}>
                                        Please submit a request below by providing the email address you have connected to your Spotify. 
                                    </p>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <InputText type='email' style={{...styles.inputText, width: '100%'}} placeholder='Enter your email here' required 
                                                onInvalid={(e) => e.target.setCustomValidity('Please enter a valid email address')}
                                                onInput={(e) => e.target.setCustomValidity('')} value={requestEmail} onChange={(e) => setRequestEmail(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Button label='Submit' severity='success' size='small' style={{marginTop: '2px', color: 'black', width: '100%'}} 
                                                disabled={loading} onClick={requestSpotifyAccess} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            {requestError && (
                                                <Message severity="error" text={requestError} />
                                            )}
                                            {requestSuccess && (
                                                <Message severity="success" text={requestSuccess} />
                                            )}
                                        </Grid>
                                    </Grid>
                                    <h3 style={styles.contentTitle}>Check Status</h3>
                                    <p style={styles.contentText}>
                                        Did you already submit a request? Check below if your request has been processed. 
                                        Please send a message in the #spotify channel of the Ratbot Discord server for the quickest response. 
                                        You can join the server through the top menu by going to About, and then Discord. 
                                    </p>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Button label="Check Request Status" severity="info" style={{color: 'black', marginTop: '16px', width: '100%'}} 
                                                disabled={loading} onClick={checkSpotifyAccess} />
                                        </Grid>
                                        {/* Display the message next to the button */}
                                        <Grid item xs={8} sx={{marginTop: '14px'}}>
                                            {checkStatus && (
                                                <Message severity="warn" text={checkStatus} />
                                            )}
                                        </Grid>
                                    </Grid>
                                </React.Fragment>)}
                        </div>
                    </StepperPanel>
                    <StepperPanel header={<StepperHeader title='Authorize Spotify' />} style={styles.stepperPanel}>
                        <div style={styles.stepperContentPanel}>
                            <h1 style={styles.contentTitle}>Spotify Authorization</h1>
                            <p style={styles.contentText}>
                                You need to allow Ratbot certain access to your Spotify account. 
                                The button below will take you to the Spotify website to grant access to the Ratbot app. 
                                This action can be reversed at any time either through your Spotify account settings 
                                (<a href="https://www.spotify.com/account/apps/" target="_blank" rel="noopener noreferrer">
                                    https://www.spotify.com/account/apps/
                                </a>).
                            </p>
                            <br />
                            <Button label='Authorize RatbotTTV' severity='success' style={{color: 'black'}} onClick={() => window.location.href = spotifyAuthUrl} />
                        </div>
                    </StepperPanel>
                </Stepper>
            )}
        </MainView>
    );
};

export default SpotifyMain;