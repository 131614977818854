import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const TrackTable = ({ tableData }) => {
    const [hoveredRow, setHoveredRow] = useState(null);
    const navigate = useNavigate();
    const style = {
    };
    
    const imageBodyTemplate = (item) => {
        return <img src={item.image} alt={item.image} width={50} style={{marginTop: '8px'}} />;
    };

    const artistTemplate = (item) => {
        return <p>{item.artists.map((artist) => artist.name).join(', ')}</p>;
    };

    const albumTemplate = (item) => {
        return (
            <p>{item.albumName}</p>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (<p style={{textAlign: 'center'}}>{rowData.id}</p>);
    };

    return (
        <DataTable value={tableData} size='small' stripedRows onRowMouseEnter={(e) => setHoveredRow(e.data.id)} onRowMouseLeave={() => setHoveredRow(null)}
            style={style} scrollable scrollHeight='62.5vh'>
            <Column field='id' body={idBodyTemplate} bodyStyle={{ width: '50px' }}></Column>
            <Column header='Image' body={imageBodyTemplate} bodyStyle={{ width: '60px' }}></Column>
            <Column field='trackName' header='Title'></Column>
            <Column header='Artist' body={artistTemplate}></Column>
            <Column header='Album' body={albumTemplate}></Column>
            <Column field='duration' header='Length'></Column>
            <Column field='songRequester' header='Requested By'></Column>
        </DataTable>
    );
};

export default TrackTable;