import { useState } from 'react';

// Custom hook definition
const useTriggerTemplates = () => {
    const defaulttimer = 600;
    const [triggerTemplates, setTriggerTemplates] = useState({
        "first_time_message": {
          "notes": "",
          "actions": [],
          "description": "First Time Message",
          "info": "Whenever a user posts for the first time ever in chat",
          "unique": true
        },
        "timer": {
          "notes": "",
          "timer": defaulttimer,
          "actions": [],
          "description": "Interval",
          "info": "Triggers every x minutes",
          "unique": false
        },
        "keyword": {
          "notes": "",
          "keyword": "",
          "actions": [],
          "description": "Keyword",
          "info": "When a specific word is included in a user chat message (case-insensitive)",
          "unique": false
        },
        "chat_inactivity": {
          "notes": "",
          "chat_inactivity": defaulttimer,
          "actions": [],
          "description": "Chat Inactivity",
          "info": "When there has been no chat messages for a set amount of time",
          "unique": false
        },
        "spotify_new_song": {
          "notes": "",
          "actions": [],
          "description": "Spotify: New Song",
          "info": "When a new song on Spotify is playing",
          "unique": true
        },
        "twitch_channel_update": {
          "notes": "",
          "actions": [],
          "description": "Channel Update",
          "info": "When the Twitch category or title changes",
          "unique": true
        },
        "twitch_channel_follow": {
          "notes": "",
          "actions": [],
          "description": "Follow",
          "info": "When a user follows your channel",
          "unique": true
        },
        "twitch_ad_break_begin": {
          "notes": "",
          "actions": [],
          "description": "Ad Break",
          "info": "When a midroll commercial break has started running",
          "unique": true
        },
        "twitch_channel_subscribe": {
          "notes": "",
          "actions": [],
          "description": "Subscribe",
          "info": "When a user subscribes to your channel for the first time",
          "unique": true
        },
        "twitch_channel_subscription_message": {
          "notes": "",
          "actions": [],
          "description": "Resubscribe",
          "info": "When a user resubscribes to your channel",
          "unique": true
        },
        "twitch_channel_subscription_gift": {
          "notes": "",
          "actions": [],
          "description": "Gift Subscriptions",
          "info": "When a user gifts subscriptions in your channel",
          "unique": true
        },
        "twitch_raid_from": {
          "notes": "",
          "actions": [],
          "description": "Raid From",
          "info": "When you get raided from another streamer",
          "unique": true
        },
        "twitch_raid_to": {
          "notes": "",
          "actions": [],
          "description": "Raid To",
          "info": "When you raid another streamer",
          "unique": true
        },
        "twitch_shoutout_create": {
          "notes": "",
          "actions": [],
          "description": "Shoutout Create",
          "info": "When you create a shoutout for another streamer",
          "unique": true
        },
        "twitch_shoutout_receive": {
          "notes": "",
          "actions": [],
          "description": "Shoutout Receive",
          "info": "When you receive a shoutout from another streamer",
          "unique": true
        },
        "twitch_channel_cheer": {
          "notes": "",
          "actions": [],
          "description": "Channel Cheer",
          "info": "When a user cheers on your channel",
          "unique": true
        },
        "twitch_hypetrain_begin": {
          "notes": "",
          "actions": [],
          "description": "Hype Train Begin",
          "info": "When a hype train begins",
          "unique": true
        },
        "twitch_hypetrain_end": {
          "notes": "",
          "actions": [],
          "description": "Hype Train End",
          "info": "When a hype train has ended",
          "unique": true
        },
        "twitch_goal_reached": {
          "notes": "",
          "actions": [],
          "description": "Channel Goal Reached",
          "info": "When a channel goal has been reached",
          "unique": true
        }
    });

    /*
      "twitch_reward_id": {
        "notes": "",
        "actions": [],
        "description": "Channel Points Reward",
        "info": "When a channel point reward with a text input is redeemed",
        "unique": false,
        "reward_id": ""
      }
    */

    return [triggerTemplates, setTriggerTemplates];
}

export default useTriggerTemplates;
