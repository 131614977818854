import { useState } from 'react';

function useActionTemplates() {
  const [actionTemplates, setActionTemplates] = useState({
    "Enhanced": {
      "message": "",
      "description": "AI Instruction"
    },
    "Standard": {
      "message": "",
      "description": "Chat Message"
    },
    "Delay": {
      "seconds": 10,
      "description": "Wait for X seconds before next action"
    },
    "Variable": {
      "message": "",
      "description": "Variable actions to be executed"
    },
    "TTS Standard": {
      "message": "",
      "description": "Reads out a TTS message"
    },
    "TTS Enhanced": {
      "message": "",
      "description": "Interprets the message as instructions, and reads out the result as TTS"
    },
    "Compare": {
      "message": "",
      "outputtype": "", // "Standard", "Enhanced", "Variable"
      "description": "Compares 2 values and performs action when the condition applies",
      "value1": "",
      "operator": "", // equals (=), is less than (<), is greater than (>), does not equal (!), is either true (|), contains (&), does not contain (^)
      "value2": ""
    },
    "Break": {
      "message": "",
      "description": "Stops further actions"
    },
    "Macro": {
      "message": "",
      "description": "Runs the actions of a pre-configured macro",
      "macro": ""
    },
    "Spotify": {
      "message": "",
      "description": "Actions applicable to Spotify integration",
      "action": "", // The Spotify action to execute
      "variable": "" // Additional optional data for the action
    },
    "Twitch": {
      "message": "",
      "description": "Twitch commands",
      "action": "", // The Twitch action to execute
      "variable": "" // Additional optional data for the action
    }
  });

  return [actionTemplates, setActionTemplates];
}

export default useActionTemplates;