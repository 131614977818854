import React, { useEffect, useState } from 'react';
import { useUserSettings } from '../../store/usersettings-context';
import "../../../node_modules/primereact/resources/themes/lara-dark-purple/theme.css";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import EditActions from '../../beta/dialogs/editActions';
import { Button } from 'primereact/button';
import { Button as MUIButton } from '@mui/material';
import useIsMobile from '../../utils/useIsMobile';
import { useUser } from '../../context/UserContext';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const Macros = ({ backgroundColor = '#5D3B9E', openDialogComponent = () => {}, userSettings = {}, update = () => {}, deletekey = () => {} }) => {
    const authCtx = useUser();
    const isMobile = useIsMobile();
    const [hoveredMacro, setHoveredMacro] = useState(null);
    const [newMacros, setNewMacros] = useState(Object.keys(userSettings.macros).length === 0 ? {} : userSettings.macros);
    const [newMacroTitle, setNewMacroTitle] = useState('');
    const [newMacroActions, setNewMacroActions] = useState(newMacroTitle === '' ? [] : userSettings.macros?.newMacroTitle?.actions || []);
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
        'scrollbar-width': 'none' // For Firefox
    };
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`,
        background10: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.1)`
    };
    const permissionDictionary = {
        'create announcement': ['moderator:manage:announcements'],
        'create clip': ['clips:edit'],
        'create shoutout': ['moderator:manage:shoutouts'],
        'snooze next ad': ['channel:manage:ads'],
        'start commercial': ['channel:edit:commercial'],
        'update category': ['channel:manage:broadcast'],
        'update title': ['channel:manage:broadcast']
    };

    useEffect(() => {
        if (newMacroTitle === '') return;
        if (Object.keys(newMacros).length === 0) return;
        const _newMacros = {...newMacros};
        delete _newMacros[''];
        update({macros: _newMacros});
    }, [newMacros]);

    const hasPermissionScope = (scope = []) => {
        if ('twitchPermissions' in userSettings === false) return false;
        if (scope.length === 0) return true;
        for (let index in scope) {
            if (userSettings['twitchPermissions'].includes(scope[index])) return true;
        }
        return false;
    };
    const missingScopes = (macroObj = {}) => {
        let returnArr = [];
        for (let actionObj of macroObj['actions']) {
            if (actionObj['type'].toLowerCase() === 'twitch') {
                const action = actionObj['action'] || '';
                if (!hasPermissionScope(permissionDictionary[action])) {
                    returnArr.push(permissionDictionary[action]);
                }
            } else if (actionObj['type'].toLowerCase() === 'compare' && 'actions' in actionObj) {
                for (let subActionObj of actionObj['actions']) {
                    if (subActionObj['type'].toLowerCase() === 'twitch') {
                        const subAction = subActionObj['action'].toLowerCase();
                        if (!hasPermissionScope(permissionDictionary[subAction])) {
                            returnArr.push(permissionDictionary[subAction]);
                        }
                    }
                }
            }
        }
        return returnArr;
    };
    const addPermission = (e, scopeArr = []) => {
        e.stopPropagation();
        openDialogComponent('addtwitchpermission', {scope: scopeArr});
    };

    const handleAccordionChange = (macroName) => (event, isExpanded) => {
        if (isExpanded) {
            setNewMacroTitle(macroName);
            setNewMacroActions(userSettings.macros[macroName]?.actions || []);
        }
    };

    return (
        <React.Fragment>
            <Grid container sx={{...hideScrollbar, maxHeight: '80vh', overflowY: 'auto', padding: !isMobile && '16px', paddingTop: '0px', paddingRight: isMobile ? '20px' : '32px'}}>
                <Button label='Add New' icon='pi pi-plus' size='small' style={{ marginBottom: 16, width: isMobile && '100%' }} onClick={() => openDialogComponent('newmacro')} />
                {Object.keys(userSettings['macros']).map((macroName, index) => (
                    <Grid item xs={12} key={index}>
                        <Accordion sx={{backgroundColor: colorScheme.background10}} onChange={handleAccordionChange(macroName)}
                            onMouseEnter={() => {!isMobile && setHoveredMacro(macroName)}} onMouseLeave={() => {!isMobile && setHoveredMacro(null)}}>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{
                                    backgroundColor: (missingScopes(userSettings['macros'][macroName]).length > 0) ? 'rgba(255, 255, 0, 0.1)' : colorScheme.background50, 
                                    position: 'relative'
                                }}
                            >
                                <h3 style={{color: 'var(--complementary-color)'}}>{`${macroName} (${userSettings['macros'][macroName]['actions'].length} actions)`}</h3>
                                {hoveredMacro === macroName && (
                                    <div style={{ position: 'absolute', right: 64, top: '25%', display: 'flex', gap: '8px' }}>
                                        <Button label='Edit' icon='pi pi-pencil' size='small' style={{ marginRight: 8 }} onClick={(e) => {
                                            e.stopPropagation();
                                            openDialogComponent('newmacro', userSettings['macros'][macroName]);
                                        }} />
                                        <Button label='Delete' icon='pi pi-trash' size='small' className='p-button-danger' onClick={(e) => {
                                            e.stopPropagation();
                                            deletekey(['macros', macroName]);
                                        }} />
                                    </div>
                                )}
                                {(missingScopes(userSettings['macros'][macroName]).length > 0) && !authCtx.editor && hoveredMacro === macroName && (
                                    <div style={{position: 'absolute', bottom: '30%', right: 280}}>
                                        <MUIButton size='medium' variant='text' color='warning' startIcon={<PriorityHighIcon />}
                                            onClick={(e) => addPermission(e, missingScopes(userSettings['macros'][macroName]))}>Action Required</MUIButton>
                                    </div>
                                )}
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: isMobile ? 2 : 4, marginBottom: '40vh' }}>
                                {isMobile && (
                                    <div style={{marginBottom: '16px', width: '100%'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button label='Edit' icon='pi pi-pencil' size='small' style={{marginRight: 8, width: '100%'}} onClick={(e) => {
                                                    e.stopPropagation();
                                                    openDialogComponent('newmacro', userSettings['macros'][macroName]);
                                                }} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button label='Delete' icon='pi pi-trash' size='small' style={{width: '100%'}} className='p-button-danger' onClick={(e) => {
                                                    e.stopPropagation();
                                                    deletekey(['macros', macroName]);
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                            <EditActions actionsArr={newMacroActions} setActionsArr={setNewMacroActions} backgroundColor={backgroundColor} userSettings={userSettings}
                                excludeArr={['macro']} save={
                                    (newActionsArr) => {
                                        if (newMacroTitle !== '') {
                                            setNewMacros((prev) => ({...prev, [newMacroTitle]: {...newMacros[newMacroTitle], actions: newActionsArr}}));
                                        }
                                    }
                                } />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
};

export default Macros;