import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import useIsMobile from '../../utils/useIsMobile';
import { Grid } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const Keywords = ({ backgroundColor = '#5D3B9E', openDialogComponent = () => {}, userSettings = {}, update = () => {}, deletekey = () => {} }) => {
    const authCtx = useUser();
    const isMobile = useIsMobile();
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
        'scrollbar-width': 'none' // For Firefox
    };
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const [keywords, setKeywords] = useState(userSettings?.keywords ? [...userSettings.keywords] : []);
    const styles = {
        inputText: {
            width: '100%',
            backgroundColor: colorScheme.background75
        },
        container: {
            ...hideScrollbar, 
            maxHeight: '80vh', 
            overflowY: 'auto', 
            padding: !isMobile && '16px', 
            paddingTop: '0px', 
            paddingRight: isMobile ? '20px' : '32px'
        },
        deleteButton: {
            width: '100%'
        },
        addButton: {
            width: '100%',
            marginTop: isMobile ? '' : '8px'
        }
    };

    useEffect(() => {
        if ('keywords' in userSettings === false) return;
        if (keywords.length !== userSettings['keywords'].length) {
            save();
        }
    }, [keywords]);

    const save = () => {
        update({keywords: [...keywords]}, true);
    };

    return (
        <Grid container spacing={2} sx={styles.container}>
            <Grid item xs={12}>
                <h2>Keywords add additional context for Ratbot when specific words or phrases are used</h2>
            </Grid>
            {!isMobile && (<Grid item xs={2}>
                <h2>Keyword</h2>
            </Grid>)}
            {!isMobile && (<Grid item xs={7}>
                <h2>Context</h2>
            </Grid>)}
            <Grid item xs={isMobile ? 12 : 3}>
                <Button severity='success' label="Add Keyword" icon="pi pi-plus" style={styles.addButton} onClick={() => {
                    setKeywords((prev) => {
                        const _arrCopy = [...prev];
                        _arrCopy.push({keyword: '', context: ''});
                        return _arrCopy;
                    });
                }} />
            </Grid>
            {keywords.map((kObj, index) => (
                <React.Fragment key={index}>
                    <Grid item xs={isMobile ? 3 : 2}>
                        <InputText style={styles.inputText} value={kObj.keyword} onChange={(e) => {
                            setKeywords((prev) => {
                                const _arrCopy = [...prev];
                                const _objCopy = {..._arrCopy[index]};
                                _objCopy.keyword = e.target.value;
                                _arrCopy[index] = {..._objCopy};
                                return _arrCopy;
                            });
                        }} onBlur={save} />
                    </Grid>
                    <Grid item xs={isMobile ? 7 : 9}>
                        <InputText style={styles.inputText} value={kObj.context} onChange={(e) => {
                            setKeywords((prev) => {
                                const _arrCopy = [...prev];
                                const _objCopy = {..._arrCopy[index]};
                                _objCopy.context = e.target.value;
                                _arrCopy[index] = {..._objCopy};
                                return _arrCopy;
                            });
                        }} onBlur={save} />
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1}>
                        <Button severity='danger' style={styles.deleteButton} size="small" icon="pi pi-times" onClick={() => {
                            setKeywords((prev) => {
                                const _arrCopy = [...prev];
                                _arrCopy.splice(index, 1);
                                return _arrCopy;
                            });
                        }} />
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};

export default Keywords;