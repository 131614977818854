import { Grid, Typography } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { ToggleButton } from 'primereact/togglebutton';
import './editCustomVariable.css';
import { Dropdown } from 'primereact/dropdown';

const EditCustomVariable = ({
    backgroundColor = '#5D3B9E',
    userSettings = {},
    update = () => {},
    code = '',
    setOpenDialog = () => {},
    setIsBlur = () => {},
    deletekey = () => {},
}) => {
    const [variable, setVariable] = useState(() => {
        const initialVariable = userSettings['customVariables'][code] || {};
        // Initialize 'value' based on datatype
        if (initialVariable['datatype'] === 'list') {
            initialVariable['value'] = Array.isArray(initialVariable['value']) ? initialVariable['value'] : [];
        } else if (initialVariable['datatype'] === 'number') {
            initialVariable['value'] = initialVariable['value'] !== undefined ? initialVariable['value'] : 0;
        } else if (initialVariable['datatype'] === 'boolean') {
            initialVariable['value'] = initialVariable['value'] !== undefined ? initialVariable['value'] : false;
        } else {
            initialVariable['value'] = initialVariable['value'] || '';
        }
        // Only initialize 'userValues' if 'unique' is true
        if (initialVariable['unique']) {
            initialVariable['userValues'] = initialVariable['userValues'] || {};
        }
        return initialVariable;
    });

    const [variableCode, setVariableCode] = useState(code);
    const [selectedUser, setSelectedUser] = useState('');

    const isAlphanumeric = (str) =>
        /^[a-zA-Z0-9 _\-()&+%#/@?!*=']*$/.test(str);

    const getCurrentValue = () => {
        let value;
        if (variable['unique'] && selectedUser !== '') {
            value =
                variable['userValues'][selectedUser] !== undefined
                    ? variable['userValues'][selectedUser]
                    : variable['value']; // Fall back to default value
        } else {
            value = variable['value'];
        }
        if (variable['datatype'] === 'list' && !Array.isArray(value)) {
            return [];
        }
        return value;
    };

    const setCurrentValue = (newValue) => {
        if (variable['unique'] && selectedUser !== '') {
            setVariable((prev) => {
                const updatedUserValues = { ...prev.userValues };
                updatedUserValues[selectedUser] = newValue;
                return { ...prev, userValues: updatedUserValues };
            });
        } else {
            setVariable((prev) => ({ ...prev, value: newValue }));
        }
    };

    const datatypeTemplates = {
        number: (
            <InputNumber
                value={getCurrentValue()}
                onValueChange={(e) => {
                    if (e.value !== null) {
                        setCurrentValue(e.value);
                    }
                }}
                showButtons
                buttonLayout="horizontal"
                step={1}
                mode="decimal"
                decrementButtonClassName="p-button-danger"
                incrementButtonClassName="p-button-success"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                style={{ width: '100%', backgroundColor }}
                className="custom-input-number"
            />
        ),
        text: (
            <InputText
                value={getCurrentValue()}
                onChange={(e) => setCurrentValue(e.target.value)}
                style={{ width: '100%', backgroundColor }}
            />
        ),
        boolean: (
            <ToggleButton
                onLabel="True"
                offLabel="False"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                style={{ width: '100%' }}
                checked={getCurrentValue()}
                onChange={(e) => setCurrentValue(e.value)}
            />
        ),
        list: (
            <>
                {Array.isArray(getCurrentValue()) &&
                    getCurrentValue().map((item, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '8px',
                            }}
                        >
                            <InputText
                                value={item}
                                onChange={(e) => {
                                    const newValue = [...getCurrentValue()];
                                    newValue[index] = e.target.value;
                                    setCurrentValue(newValue);
                                }}
                                style={{ flexGrow: 1, backgroundColor }}
                            />
                            <Button
                                icon="pi pi-trash"
                                className="p-button-danger"
                                onClick={() => {
                                    const newValue = [...getCurrentValue()];
                                    newValue.splice(index, 1);
                                    setCurrentValue(newValue);
                                }}
                                style={{ marginLeft: '8px' }}
                            />
                        </div>
                    ))}
                <Button
                    label="Add Item"
                    icon="pi pi-plus"
                    onClick={() => {
                        const currentList = getCurrentValue() || [];
                        const newValue = [...currentList, ''];
                        setCurrentValue(newValue);
                    }}
                    style={{ marginTop: '8px' }}
                />
            </>
        ),
    };

    const isSaveDisabled = () => {
        if (variableCode.length < 1 || variableCode.length > 50) return true;
        if (variable.title.length < 1 || variable.title.length > 50)
            return true;
        if (
            variable.description.length < 1 ||
            variable.description.length > 500
        )
            return true;
        if (
            !isAlphanumeric(variableCode) ||
            !isAlphanumeric(variable.title) ||
            !isAlphanumeric(variable.description)
        )
            return true;
        if (variableCode !== code) {
            if (variableCode in userSettings['customVariables']) return true;
        }
        return false;
    };

    const saveHandler = () => {
        const _customVariables = { ...userSettings['customVariables'] };
        _customVariables[variableCode] = { ...variable };
        if (variableCode !== code) {
            deletekey(['customVariables', code]);
            delete _customVariables[code];
        }
        update({ customVariables: _customVariables });
        setOpenDialog(false);
        setIsBlur(false);
    };

    const getDropdownOptions = () => {
        if (
            !variable['unique'] ||
            !variable['userValues'] ||
            Object.keys(variable['userValues']).length === 0
        ) {
            return [];
        }
        return Object.keys(variable['userValues']).map((u) => ({
            username: u,
        }));
    };

    return (
        <DialogComponent
            footer="Set properties of your custom variable"
            maxHeight="85vh"
            maxWidth="40vw"
            minWidth="500px"
        >
            <Grid container spacing={2} sx={{ marginLeft: '0px' }}>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Typography variant="h4">Code</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputText
                            value={variableCode}
                            onChange={(e) => setVariableCode(e.target.value)}
                            style={{ width: '100%', backgroundColor }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Typography variant="h4">Title</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputText
                            value={variable.title}
                            onChange={(e) =>
                                setVariable({
                                    ...variable,
                                    title: e.target.value,
                                })
                            }
                            style={{ width: '100%', backgroundColor }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Description</Typography>
                    <InputText
                        value={variable.description}
                        onChange={(e) =>
                            setVariable({
                                ...variable,
                                description: e.target.value,
                            })
                        }
                        style={{ width: '100%', backgroundColor }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h4" sx={{ marginBottom: '8px' }}>
                                Current Value
                            </Typography>
                        </Grid>
                        {variable['unique'] && (
                            <Grid item xs={12}>
                                <Dropdown
                                    style={{
                                        backgroundColor,
                                        marginBottom: '16px',
                                    }}
                                    value={
                                        selectedUser
                                            ? { username: selectedUser }
                                            : null
                                    }
                                    options={getDropdownOptions()}
                                    optionLabel="username"
                                    placeholder="Select a user (or edit default value)"
                                    onChange={(e) =>
                                        setSelectedUser(e.value.username)
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {datatypeTemplates[variable['datatype']]}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '16px' }}>
                    <Button
                        label="Save"
                        severity="success"
                        size="small"
                        style={{
                            width: '25%',
                            float: 'right',
                            marginLeft: '16px',
                        }}
                        disabled={isSaveDisabled()}
                        onClick={saveHandler}
                    />
                    <Button
                        label="Close"
                        severity="danger"
                        size="small"
                        style={{ width: '25%', float: 'right' }}
                        onClick={() => {
                            setOpenDialog(false);
                            setIsBlur(false);
                        }}
                    />
                </Grid>
            </Grid>
        </DialogComponent>
    );
};

export default EditCustomVariable;
