import React from 'react';
import { Box, keyframes, styled } from '@mui/material';

const AnimatedBackground = ({ children, selectedImages, tintColor = '#dede18' }) => {
  const rotate = keyframes`from {transform: rotate(0deg);} to {transform: rotate(360deg);}`;
  const antirotate = keyframes`from {transform: rotate(0deg);} to {transform: rotate(-360deg);}`;

  // Background and Foreground image containers with desaturation applied
  const BackgroundContainer = styled(Box)({
    backgroundImage: `url(${selectedImages[0]})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    filter: 'blur(10px) brightness(0.3) saturate(0) grayscale(0.5)', // Apply desaturation and grayscale for more neutral base
    position: 'absolute',
    top: '-100%',
    left: '-100%',
    right: 0,
    bottom: 0,
    zIndex: -3,
    animation: `${rotate} 540s linear infinite`,
  });

  const ForegroundContainer = styled(Box)({
    backgroundImage: `url(${selectedImages[1]})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    filter: 'blur(10px) brightness(0.3) saturate(0) grayscale(0.5)', // Apply desaturation and grayscale for more neutral base
    position: 'absolute',
    top: '-100%',
    left: '-100%',
    right: 0,
    bottom: 0,
    zIndex: -2,
    animation: `${antirotate} 720s linear infinite`,
    opacity: 0.25,
  });

  // Tint overlay applied on top of background and foreground
  const TintOverlay = styled(Box)({
    position: 'absolute',
    top: '-100%',
    left: '-100%',
    right: 0,
    bottom: 0,
    zIndex: -1,
    backgroundColor: tintColor,
    opacity: 0.4, // Adjust to control how strong the tint should be
    pointerEvents: 'none', // Ensures this doesn't interfere with interaction
  });

  return (
    <React.Fragment>
      <BackgroundContainer sx={{ height: '300%', width: '300%' }} />
      <ForegroundContainer sx={{ height: '300%', width: '300%' }} />
      <TintOverlay sx={{ height: '300%', width: '300%' }} />
      {children}
    </React.Fragment>
  );
};

export default React.memo(AnimatedBackground);