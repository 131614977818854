import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Grid } from '@mui/material';
import { useUser } from '../../context/UserContext';
import { useUserSettings } from '../../store/usersettings-context';
import { PickList } from 'primereact/picklist';

const SpotifyBlockedGenres = ({ backgroundColor = '#5D3B9E', footer = '' }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const [genres, setGenres] = useState([]);
    const [blockedGenres, setBlockedGenres] = useState(userSettings.spotify.blockedGenres);

    const getGenreSeeds = async () => {
        try {
            const response = await fetch(`${apiUrl}/spotify/getgenreseeds?refreshtoken=${userSettings.spotify.refreshToken}`);
            if (response.ok) {
                const data = await response.json();
                setGenres(data['genres'].filter((v, i) => !blockedGenres.includes(v)));
            } else {
                setGenres([
                    "acoustic", "afrobeat", "alt-rock", "alternative", "ambient", "anime", 
                    "black-metal", "bluegrass", "blues", "bossanova", "brazil", "breakbeat", 
                    "british", "cantopop", "chicago-house", "children", "chill", "classical", 
                    "club", "comedy", "country", "dance", "dancehall", "death-metal", "deep-house", 
                    "detroit-techno", "disco", "disney", "drum-and-bass", "dub", "dubstep", 
                    "edm", "electro", "electronic", "emo", "folk", "forro", "french", "funk", 
                    "garage", "german", "gospel", "goth", "grindcore", "groove", "grunge", 
                    "guitar", "happy", "hard-rock", "hardcore", "hardstyle", "heavy-metal", 
                    "hip-hop", "holidays", "honky-tonk", "house", "idm", "indian", "indie", 
                    "indie-pop", "industrial", "iranian", "j-dance", "j-idol", "j-pop", "j-rock", 
                    "jazz", "k-pop", "kids", "latin", "latino", "malay", "mandopop", "metal", 
                    "metal-misc", "metalcore", "minimal-techno", "movies", "mpb", "new-age", 
                    "new-release", "opera", "pagode", "party", "philippines-opm", "piano", 
                    "pop", "pop-film", "post-dubstep", "power-pop", "progressive-house", 
                    "psych-rock", "punk", "punk-rock", "r-n-b", "rainy-day", "reggae", "reggaeton", 
                    "road-trip", "rock", "rock-n-roll", "rockabilly", "romance", "sad", "salsa", 
                    "samba", "sertanejo", "show-tunes", "singer-songwriter", "ska", "sleep", 
                    "songwriter", "soul", "soundtracks", "spanish", "study", "summer", "swedish", 
                    "synth-pop", "tango", "techno", "trance", "trip-hop", "turkish", "work-out", 
                    "world-music"
                ].filter((v, i) => !blockedGenres.includes(v)));
            }
        } catch (error) {
            
        }
    };
    /**
     * Handles moving genres between the available and blocked genres.
     * @param {Object} event The event object containing the updated source and target lists.
     */
    const handleChange = (event) => {
        setGenres(event.source);
        setBlockedGenres(event.target);
        update({spotify: {...userSettings.spotify, blockedGenres: event.target}});
    };
    /**
     * Capitalizes the first letter of the genre item.
     * @param {string} genre The genre name to be capitalized.
     * @returns {string} The genre name with the first letter capitalized.
     */
    const capitalizeFirstLetter = (genre) => {
        return genre.charAt(0).toUpperCase() + genre.slice(1);
    };

    useEffect(() => {
        getGenreSeeds();
    }, []);

    return (
        <DialogComponent footer={footer} maxHeight="75vh" minWidth="350px" maxWidth="1000px">
            <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px', width: '100%'}}>
                <PickList source={genres.sort()} target={blockedGenres.sort()} sourceHeader="Available Genres" targetHeader="Blocked Genres" 
                    itemTemplate={(item) => <span>{capitalizeFirstLetter(item)}</span>} showSourceControls={false} showTargetControls={false} 
                    onChange={handleChange} sourceStyle={{height: '30rem'}} targetStyle={{height: '30rem'}} style={{width: '100%'}} />
            </Grid>
        </DialogComponent>
    );
};

export default SpotifyBlockedGenres;