import { useMemo, useState } from 'react';

/**
 * Custom hook to determine a high-contrast complementing text color based on the provided color.
 * It uses the color wheel to provide a complementary (opposite) color as well as luminosity adjustment.
 *
 * @param {string} initialHexColor - The initial background color in hex format (e.g., '#ff5733').
 * @returns {[string, function]} - The complementing text color in hex format and a function to update the hex color.
 */
const useComplementaryTextColor = (initialHexColor) => {
  const [hexColor, setHexColor] = useState(initialHexColor);

  const hexToRgb = (hex) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 7) {
      r = parseInt(hex.slice(1, 3), 16);
      g = parseInt(hex.slice(3, 5), 16);
      b = parseInt(hex.slice(5, 7), 16);
    } else if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    return { r, g, b };
  };

  const rgbToHex = (r, g, b) => {
    const toHex = (c) => c.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  const getComplementaryColor = ({ r, g, b }) => {
    // Get the complementary color by subtracting each channel from 255
    return { r: 255 - r, g: 255 - g, b: 255 - b };
  };

  const getLuminance = ({ r, g, b }) => {
    const [rNorm, gNorm, bNorm] = [r / 255, g / 255, b / 255];
    const rgb = [rNorm, gNorm, bNorm].map((channel) => {
      return channel <= 0.03928
        ? channel / 12.92
        : Math.pow((channel + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
  };

  const textColor = useMemo(() => {
    if (!/^#([0-9A-Fa-f]{3}){1,2}$/.test(hexColor)) {
      console.warn('Invalid hex color provided to useComplementaryTextColor hook');
      return '#000000'; // Default to black if invalid color
    }

    const rgbColor = hexToRgb(hexColor);
    const luminance = getLuminance(rgbColor);

    // Determine if background is light or dark
    const isBackgroundLight = luminance > 0.5;

    // Set the text color based on background brightness for higher contrast
    let textColor;
    if (isBackgroundLight) {
      // If background is light, set the text color to a darker shade (e.g., near black)
      textColor = rgbToHex(0, 0, 0); // black text for light backgrounds
    } else {
      // If background is dark, set the text color to a lighter shade (e.g., near white)
      textColor = rgbToHex(255, 255, 255); // white text for dark backgrounds
    }

    return textColor;
  }, [hexColor]);

  return [textColor, setHexColor];
};

export default useComplementaryTextColor;