import React, { useEffect, useState } from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Chip, Grid, Stack, Typography } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { useDebounce } from 'primereact/hooks';
import { useUser } from '../../context/UserContext';
import { useUserSettings } from '../../store/usersettings-context';

const SongList = ({songs = [], setCurrentSong = () => {}, backgroundColor = '#5D3B9E', userSettings = {}}) => {
    const [selectedSong, setSelectedSong] = useState('');

    useEffect(() => {
        if (selectedSong !== '') {
            for (let trackObj of songs) {
                if (trackObj.track.id === selectedSong) {
                    setCurrentSong(trackObj);
                }
            }
        }
    }, [selectedSong]);

    const getOptions = () => {
        const tmpArr = [];
        for (let trackObj of songs) {
            const tmpObj = {};
            tmpObj['artist'] = trackObj.artist.name;
            tmpObj['album'] = trackObj.album.name;
            tmpObj['name'] = trackObj.track.name;
            tmpObj['value'] = trackObj.track.id;
            tmpObj['img'] = trackObj.album.link;
            tmpObj['label'] = `"${trackObj.track.name}" by ${trackObj.artist.name}`;
            if (!userSettings['spotify']['blockedTracks'].some((t, i) => t.id === trackObj.track.id)) {
                tmpArr.push(tmpObj);
            }
        }
        return tmpArr;
    };
    const trackTemplate = (option) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <img alt={option.img} src={option.img} width='50px' />
                </Grid>
                <Grid item xs={10}>
                    <div>{option.label}</div>
                    <div>{option.album}</div>
                </Grid>
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <ListBox value={selectedSong} onChange={(e) => setSelectedSong(e.value)} options={getOptions()} itemTemplate={trackTemplate}
                style={{maxHeight: '25vh', overflowY: 'auto', backgroundColor}} />
        </React.Fragment>
    );
};
const ArtistList = ({artists = [], setCurrentArtist = () => {}, backgroundColor = '#5D3B9E', userSettings = {}}) => {
    const [selectedArtist, setSelectedArtist] = useState('');

    useEffect(() => {
        if (selectedArtist !== '') {
            for (let artistObj of artists) {
                if (artistObj.artist.id === selectedArtist) {
                    setCurrentArtist(artistObj.artist);
                }
            }
        }
    }, [selectedArtist]);

    const getOptions = () => {
        const tmpArr = [];
        for (let artistObj of artists) {
            const tmpObj = {};
            tmpObj['artist'] = artistObj.artist.name;
            tmpObj['name'] = artistObj.artist.name;
            tmpObj['value'] = artistObj.artist.id;
            tmpObj['img'] = artistObj.artist.image;
            tmpObj['label'] = artistObj.artist.name;
            tmpObj['followers'] = artistObj.artist.followers;
            tmpObj['popularity'] = artistObj.artist.popularity;
            if (!userSettings['spotify']['blockedArtists'].some((t, i) => t.id === artistObj.artist.id)) {
                tmpArr.push(tmpObj);
            }
        }
        return tmpArr;
    };
    const artistTemplate = (option) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <img alt={option.img} src={option.img} width='50px' />
                </Grid>
                <Grid item xs={10}>
                    <div>{option.label}</div>
                    <div>{`Popularity: ${option.popularity}%, Followers: ${option.followers}`}</div>
                </Grid>
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <ListBox value={selectedArtist} onChange={(e) => setSelectedArtist(e.value)} options={getOptions()} itemTemplate={artistTemplate}
                style={{maxHeight: '25vh', overflowY: 'auto', backgroundColor}} />
        </React.Fragment>
    );
};

const SpotifyBlockListDialog = ({backgroundColor = '#5D3B9E', footer = '', blockList = 'blockedTracks'}) => {
    const authCtx = useUser();
    const [newTrack, debouncedNewTrack, setNewTrack] = useDebounce('', 500);
    const [searchRes, setSearchRes] = useState([]);
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const apiUrl = process.env.REACT_APP_API_URL;

    const getSpotifySearch = async (query = '') => {
        try {
            if (blockList === 'blockedTracks' && query !== '') {
                let url = `${apiUrl}/spotify/search?token=${authCtx.token}&refreshtoken=${encodeURIComponent(userSettings['spotify']['refreshToken'])}&q=${encodeURIComponent(query)}&limit=25&type=track`;
                const response = await fetch(`${url}`);
                if (response.ok) {
                    const res = await response.json();
                    const tmpArr = [];
                    for (let item of res['tracks']['items']) {
                        const tmpObj = {};
                        tmpObj['album'] = {}; 
                        tmpObj['album']['name'] = item['album']['name']; 
                        tmpObj['album']['link'] = item['album']['images'][1]['url'];
                        tmpObj['artist'] = {}; 
                        tmpObj['artist']['name'] = item['artists'][0]['name'];
                        tmpObj['track'] = {}; 
                        tmpObj['track']['name'] = item['name']; 
                        tmpObj['track']['id'] = item['id'];
                        tmpObj['preview_url'] = item['preview_url'];
                        tmpArr.push(tmpObj);
                    }
                    setSearchRes(tmpArr);
                }
            } else if (blockList === 'blockedArtists' && query !== '') {
                let url = encodeURI(`${apiUrl}/spotify/search?token=${authCtx.token}&refreshtoken=${userSettings['spotify']['refreshToken']}&q=${query}&limit=25&type=artist`);
                const response = await fetch(`${url}`);
                if (response.ok) {
                    const res = await response.json();
                    const tmpArr = [];
                    for (let item of res['artists']['items']) {
                        const tmpObj = {};
                        tmpObj['artist'] = {}; 
                        tmpObj['artist']['name'] = item['name']; 
                        item['images'].length > 0 ? tmpObj['artist']['image'] = item['images'][1]['url'] : tmpObj['artist']['image'] = 'https://i.imgur.com/VuKjny1.png';
                        tmpObj['artist']['followers'] = item['followers']['total']; 
                        tmpObj['artist']['popularity'] = item['popularity'];
                        tmpObj['artist']['id'] = item['id'];
                        tmpArr.push(tmpObj);
                    }
                    setSearchRes(tmpArr);
                }
            } else if (query === '') {
                setSearchRes([]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getSpotifySearch(debouncedNewTrack);
    }, [debouncedNewTrack]);

  return (
    <DialogComponent footer={footer} maxHeight='75vh' minWidth='350px' maxWidth='750px'>
        <Grid container style={{height: '100%', marginTop: '8px', paddingLeft: '16px'}}>
            <Grid item xs={12}>
                {blockList === 'blockedTracks' && (<Typography variant='h4'>Add track to block</Typography>)}
                {blockList === 'blockedArtists' && (<Typography variant='h4'>Add artist to block</Typography>)}
            </Grid>
            <Grid item xs={12}>
                <span className='p-input-icon-left' style={{width: '100%'}}>
                    <i className='pi pi-search' style={{marginTop: '-4px', marginLeft: '8px'}} />
                    <InputText style={{marginTop: '8px', width: '100%', backgroundColor: colorScheme.background50, paddingLeft: '32px'}} value={newTrack} 
                        onChange={(e) => setNewTrack(e.target.value)} placeholder='Start typing to search' />
                </span>
            </Grid>
            <Grid item xs={12} sx={{marginTop: '8px'}}>
                {blockList === 'blockedTracks' && (<SongList userSettings={userSettings} backgroundColor={colorScheme.background50} songs={searchRes} setCurrentSong={(s) => {
                    const tmpObj = {};
                    tmpObj['id'] = s.track.id;
                    tmpObj['name'] = s.track.name;
                    tmpObj['artist'] = s.artist.name;
                    tmpObj['album'] = s.album.name;
                    if (!userSettings['spotify'][blockList].some((obj) => obj.id === tmpObj['id'])) {
                        update({spotify: {...userSettings['spotify'], [blockList]: [...userSettings['spotify'][blockList], tmpObj]}});
                    }
                }} />)}
                {blockList === 'blockedArtists' && (
                    <ArtistList userSettings={userSettings} backgroundColor={colorScheme.background50} artists={searchRes} setCurrentArtist={(a) => {
                        const tmpObj = {};
                        tmpObj['id'] = a.id;
                        tmpObj['name'] = a.name;
                        if (!userSettings['spotify'][blockList].some((obj) => obj.id === tmpObj['id'])) {
                            update({spotify: {...userSettings['spotify'], [blockList]: [...userSettings['spotify'][blockList], tmpObj]}});
                        }
                    }} />
                )}
            </Grid>
            <Grid item xs={12} sx={{marginTop: '16px'}}>
                {blockList === 'blockedTracks' && (<Typography variant='h4'>Blocked tracks</Typography>)}
                {blockList === 'blockedArtists' && (<Typography variant='h4'>Blocked artists</Typography>)}
            </Grid>
            <Grid item xs={12} sx={{marginTop: '8px'}}>
                <Stack direction="row" flexWrap="wrap">
                    <React.Fragment>
                        {userSettings['spotify'][blockList].map((obj) => (
                            <React.Fragment key={obj.id}>
                                {blockList === 'blockedTracks' && (<Chip label={`${obj.name} by ${obj.artist}`} sx={{m: '4px', backgroundColor: colorScheme.background75}}
                                    onDelete={() => {
                                    const newArr = userSettings['spotify']['blockedTracks'].filter(t => t.id !== obj.id);
                                    update({spotify:{...userSettings['spotify'], blockedTracks: newArr}});
                                }} />)}
                                {blockList === 'blockedArtists' && (<Chip label={`${obj.name}`} sx={{m: '4px', backgroundColor: colorScheme.background75}}
                                    onDelete={() => {
                                    const newArr = userSettings['spotify']['blockedArtists'].filter(t => t.id !== obj.id);
                                    update({spotify:{...userSettings['spotify'], blockedArtists: newArr}});
                                }} />)}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                </Stack>
            </Grid>
        </Grid>
    </DialogComponent>
  );
};

export default SpotifyBlockListDialog;