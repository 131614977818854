import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { ColorPicker } from 'primereact/colorpicker';
import { Button } from 'primereact/button';
import { Grid } from '@mui/material';
import DialogComponent from '../layouts/DialogComponent';
import useIsMobile from '../../utils/useIsMobile';
import { useUser } from '../../context/UserContext';

const UpdateReward = ({ backgroundColor = '#5D3B9E', footer = '', rewardObj = {} }) => {
    const isMobile = useIsMobile();
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const [responseText, setResponseText] = useState('');
    const [loading, setIsLoading] = useState(false);
    const [reward, setReward] = useState({
        title: rewardObj.title || '',
        background_color: rewardObj.background_color || '#5D3B9E',
        is_enabled: rewardObj.is_enabled || false,
        cost: rewardObj.cost || 0,
        prompt: rewardObj.prompt || '',
        is_user_input_required: rewardObj.is_user_input_required || false,
        max_per_stream: rewardObj.max_per_stream_setting?.max_per_stream || 0,
        max_per_user_per_stream: rewardObj.max_per_user_per_stream_setting?.max_per_user_per_stream || 0,
        global_cooldown_seconds: rewardObj.global_cooldown_setting?.global_cooldown_seconds || 0,
        is_paused: rewardObj.is_paused || false,
        should_redemptions_skip_request_queue: rewardObj.should_redemptions_skip_request_queue || false,
        is_max_per_stream_enabled: rewardObj.is_max_per_stream_enabled || false,
        is_max_per_user_per_stream_enabled: rewardObj.is_max_per_user_per_stream_enabled || false,
        is_global_cooldown_enabled: rewardObj.is_global_cooldown_enabled || false
    });
    const styles = {
        inputText: {
            width: '100%',
            backgroundColor: 'var(--background-color-darker)'
        },
        inputNumber: {
            width: '100%',
            backgroundColor: 'var(--background-color-darker)'
        },
        colorPicker: {
            marginRight: '16px'
        },
        checkBox: {
            marginRight: '16px'
        },
        label: {
            color: 'var(--complementary-color)'
        },
        button: {
            width: isMobile ? '100%' : '50%',
            color: 'black'
        }
    };
    const tooltips = {
        title: 'The title may contain a maximum of 45 characters and it must be unique among channel point rewards.',
        cost: 'The cost of the reward, in channel points. The minimum is 1 point.',
        prompt: 'The prompt shown to the viewer when they redeem the reward. The prompt is limited to a maximum of 200 characters.',
        background_color: 'The background color to use for the reward.',
        is_enabled: 'Viewers see only enabled rewards.',
        is_user_input_required: 'Whether users must enter information to redeem the reward.',
        max_per_stream: 'Limit the maximum number of redemptions allowed per live stream. Set 0 to disable.',
        max_per_user_per_stream: 'The maximum number of redemptions allowed per user per stream. Set 0 to disable.',
        global_cooldown_seconds: 'The cooldown period to use the reward, in seconds. For this to be shown in the Twitch UI, a minimum value of 60 is needed.',
        is_paused: "Viewers can't redeem paused rewards.",
        should_redemptions_skip_request_queue: 'Whether redemptions should be set to fulfilled status immediately when a reward is redeemed.'
    };
    const [tooltipsEnabled, setTooltipsEnabled] = useState(true);
    useEffect(() => {
        if (isMobile) {
            setTooltipsEnabled(false);
        } else {
            // Load tooltip preference from local storage
            const savedTooltipPreference = localStorage.getItem('tooltipsEnabled');
            if (savedTooltipPreference !== null) {
                setTooltipsEnabled(JSON.parse(savedTooltipPreference));
            }
        }
    }, []);

    const handleTooltipToggle = () => {
        setTooltipsEnabled((prev) => {
            const newValue = !prev;
            localStorage.setItem('tooltipsEnabled', JSON.stringify(newValue)); // Save to local storage
            return newValue;
        });
    };
    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
    
        setReward((prevReward) => {
            const updatedReward = {
                ...prevReward,
                [name]: newValue,
                // Conditionally setting additional fields based on current values
                is_max_per_stream_enabled: prevReward.max_per_stream > 0 ? true : false,
                is_max_per_user_per_stream_enabled: prevReward.max_per_user_per_stream > 0 ? true : false,
                is_global_cooldown_enabled: prevReward.global_cooldown_seconds > 0 ? true : false,
            };
            // Handle edge cases where the current change impacts these fields
            if (name === 'max_per_stream') {
                updatedReward.is_max_per_stream_enabled = newValue > 0 ? true : false;
            }
            if (name === 'max_per_user_per_stream') {
                updatedReward.is_max_per_user_per_stream_enabled = newValue > 0 ? true : false;
            }
            if (name === 'global_cooldown_seconds') {
                updatedReward.is_global_cooldown_enabled = newValue > 0 ? true : false;
            }
    
            return updatedReward;
        });
    };
    const handleColorChange = (color) => {
        setReward((prevReward) => ({
            ...prevReward,
            background_color: `#${color.replace('#', '')}` // Ensures the color always starts with '#'
        }));
    };    
    const handleSubmit = async () => {
        try {
            console.log('Updated Reward Data:', reward);
            setIsLoading(true);
            setResponseText('');
            const response = await fetch(`${apiUrl}/user/customreward?token=${authCtx.token}&id=${rewardObj.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reward)
            });
            const data = await response.json();
            if (response.ok) {
                setResponseText(`Reward has been updated successfully!`);
                setIsLoading(false);
            } else {
                setResponseText(`An error occurred: ${data.error}`);
                setIsLoading(false);
            }
        } catch (error) {
            setResponseText(`An error occurred: ${error.message}`);
            setIsLoading(false);
        }
    };

    return (
        <DialogComponent footer={footer} maxHeight='75vh' maxWidth='50vw'>
            <Grid container style={{ marginTop: '8px', paddingLeft: '16px' }} spacing={2}>
                <Grid item xs={12} sm={6}>
                    <label style={styles.label} htmlFor="title">Title</label>
                    <InputText tooltipOptions={{position: 'top'}} tooltip={tooltipsEnabled ? tooltips.title : null} maxLength={45} style={styles.inputText} id="title" name="title" value={reward.title} onChange={handleChange} className="p-inputtext-sm" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label style={styles.label} htmlFor="cost">Cost</label>
                    <InputNumber style={styles.inputNumber} id="cost" name="cost" value={reward.cost} showButtons buttonLayout="horizontal" step={100} tooltip={tooltipsEnabled ? tooltips.cost : null}
                        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" min={1} tooltipOptions={{position: 'top'}}
                        decrementButtonIcon="pi pi-minus" onChange={(e) => handleChange({ target: { name: 'cost', value: e.value } })} className="p-inputtext-sm" />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <label style={styles.label} htmlFor="prompt">Prompt</label>
                    <InputText disabled={!reward.is_user_input_required}  maxLength={200} style={styles.inputText} id="prompt" name="prompt" 
                        value={reward.prompt} onChange={handleChange} className="p-inputtext-sm" tooltip={tooltipsEnabled ? tooltips.prompt : null} tooltipOptions={{position: 'top'}} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Checkbox style={styles.checkBox} inputId="is_enabled" name="is_enabled" checked={reward.is_enabled} onChange={handleChange}
                        tooltip={tooltipsEnabled ? tooltips.is_enabled : null} tooltipOptions={{position: 'top'}} />
                    <label style={styles.label} htmlFor="is_enabled">Is Enabled</label>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Checkbox style={styles.checkBox} inputId="is_user_input_required" name="is_user_input_required" checked={reward.is_user_input_required} 
                        onChange={handleChange} tooltip={tooltipsEnabled ? tooltips.is_user_input_required : null} tooltipOptions={{position: 'top'}} />
                    <label style={styles.label} htmlFor="is_user_input_required">User Input Required</label>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Checkbox style={styles.checkBox} inputId="is_paused" name="is_paused" checked={reward.is_paused} onChange={handleChange}
                        tooltip={tooltipsEnabled ? tooltips.is_paused : null} tooltipOptions={{position: 'top'}} />
                    <label style={styles.label} htmlFor="is_paused">Is Paused</label>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Checkbox style={styles.checkBox} inputId="should_redemptions_skip_request_queue" name="should_redemptions_skip_request_queue" 
                        checked={reward.should_redemptions_skip_request_queue} onChange={handleChange}
                        tooltip={tooltipsEnabled ? tooltips.should_redemptions_skip_request_queue : null} tooltipOptions={{position: 'top'}} />
                    <label style={styles.label} htmlFor="should_redemptions_skip_request_queue">Skip Request Queue</label>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label style={styles.label} htmlFor="max_per_stream">Max Per Stream</label>
                    <InputNumber style={styles.inputNumber} id="max_per_stream" name="max_per_stream" value={reward.max_per_stream} showButtons buttonLayout="horizontal" step={1}
                        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                        onChange={(e) => handleChange({ target: { name: 'max_per_stream', value: e.value } })} className="p-inputtext-sm" min={0} 
                        tooltip={tooltipsEnabled ? tooltips.max_per_stream : null} tooltipOptions={{position: 'top'}} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label style={styles.label} htmlFor="max_per_user_per_stream">Max Per User Per Stream</label>
                    <InputNumber style={styles.inputNumber} id="max_per_user_per_stream" name="max_per_user_per_stream" value={reward.max_per_user_per_stream} 
                        showButtons buttonLayout="horizontal" step={1} decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" 
                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" className="p-inputtext-sm" min={0}
                        onChange={(e) => handleChange({ target: { name: 'max_per_user_per_stream', value: e.value } })}
                        tooltip={tooltipsEnabled ? tooltips.max_per_user_per_stream: null} tooltipOptions={{position: 'top'}} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label style={styles.label} htmlFor="global_cooldown_seconds">Global Cooldown Seconds</label>
                    <InputNumber style={styles.inputNumber} id="global_cooldown_seconds" name="global_cooldown_seconds" value={reward.global_cooldown_seconds} 
                        showButtons buttonLayout="horizontal" step={1} decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" 
                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" className="p-inputtext-sm" min={0} max={604800}
                        onChange={(e) => handleChange({ target: { name: 'global_cooldown_seconds', value: e.value } })}
                        tooltip={tooltipsEnabled ? tooltips.global_cooldown_seconds : null} tooltipOptions={{position: 'top'}} />
                </Grid>
                <Grid item xs={12} sm={6} sx={{marginTop: '28px'}}>
                    <ColorPicker style={styles.colorPicker} id="background_color" value={reward.background_color} onChange={(e) => handleColorChange(e.value)}
                        tooltip={tooltipsEnabled ? tooltips.background_color : null} tooltipOptions={{position: 'top'}} />
                        <label style={styles.label} htmlFor="background_color">Background Color</label>
                </Grid>
                {!isMobile && (
                    <Grid item xs={12} sx={{marginTop: '16px'}}>
                        <Checkbox inputId="tooltipsEnabled" name="tooltipsEnabled" checked={tooltipsEnabled} onChange={handleTooltipToggle} />
                        <label htmlFor="tooltipsEnabled" style={{marginLeft: '8px', color: 'var(--complementary-color)'}}>Enable Tooltips</label>
                    </Grid>
                )}
                <Grid item xs={12} sx={{marginTop: '16px'}}>
                    <Button style={styles.button} severity='success' label="Update Reward" onClick={handleSubmit} className="p-button-sm p-button-primary"
                        disabled={loading} />
                </Grid>
                {responseText !== '' && (
                    <Grid item xs={12}>
                        <h3 style={{color: 'var(--complementary-text)', margin: '0px', padding: '0px'}}>{responseText}</h3>
                    </Grid>
                )}
            </Grid>
        </DialogComponent>
    );
};

export default UpdateReward;