import { Grid, Tooltip } from '@mui/material';
import React, { useRef } from 'react';
import useSystemVariables from '../../utils/useSystemVariables';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const Variables = ({backgroundColor = '#5D3B9E'}) => {
    const [systemVariables, setSystemVariables] = useSystemVariables();
    const isMobile = useIsMobile();
    const isLarge = useWindowSizeThreshold(1280, true);
    const toast = useRef(null); // Toast reference for showing notifications
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
        'scrollbar-width': 'none' // For Firefox
    };

    const SystemVariable = ({ variable }) => {
        const maxDescriptionLength = 100;

        const copyToClipboard = (text, e) => {
            navigator.clipboard.writeText(text).then(() => {
                if (toast.current) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Copied!',
                        detail: `Copied: "${text}"`,
                        life: 3000
                    });
                }
                // Remove focus from the button
                e.target.blur();
            });
        };
    
        const truncate = (text, length) => {
            if (text.length <= length) return text;
            return text.substring(0, length) + "...";
        };

        const cardStyle = {
            background: `radial-gradient(circle, ${colorScheme.background75} 0%, ${colorScheme.background25} 100%)`,
            border: `2px solid ${colorScheme.background75}`,
            width: '100%'
        };
    
        return (
            <Grid item xs={(isMobile && isLarge) ? 12 : (!isMobile && isLarge) ? 3 : 2} 
                sx={{...cardStyle, position: 'relative', borderRadius: '5px', margin: '8px', height: '180px', paddingRight: '8px'}}>
                <h3 style={{margin: '0px', padding: '0px', color: 'var(--complementary-color)'}}>{variable['title']}</h3>
                {variable['description'].length > maxDescriptionLength ? (
                <Tooltip title={<p style={{color: 'var(--complementary-color)'}}>{variable['description']}</p>} placement="top" arrow>
                    <p style={{color: 'var(--complementary-color)'}}>{truncate(variable['description'], maxDescriptionLength)}</p>
                </Tooltip>
                ) : (
                    <p style={{color: 'var(--complementary-color)'}}>{variable['description']}</p>
                )}
                <Button icon='pi pi-copy' iconPos='right' style={{position: 'absolute', bottom: 8, left: 8, padding: '2px', margin: '0px'}} text severity='success'
                    onClick={(e) => {
                        copyToClipboard(variable['copy'], e);
                    }} label={variable['copy']} size='small' />
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Grid container spacing={2} sx={{...hideScrollbar, maxHeight: '80vh', overflowY: 'auto', padding: '16px', paddingTop: isMobile && '0px', paddingRight: isMobile && '0px', paddingBottom: isMobile && '72px', marginTop: '16px'}}>
                    {systemVariables.map((vObj, index) => (
                        <React.Fragment key={index}>
                            <SystemVariable variable={vObj} />
                        </React.Fragment>
                    ))}
            </Grid>
        </React.Fragment>
    );    
};

export default Variables;