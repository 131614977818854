import { Box, Grid } from '@mui/material';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';

const ChatGeneralSettings = ({backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, openDialogComponent = () => {}, setIsBlur = () => {}, refresh = () => {}}) => {
    const styles = {
        container: {
            backgroundColor,
            width: '100%',
            height: '70vh',
            paddingLeft: '32px',
            paddingRight: '32px',
            paddingTop: '32px'
        },
        noMarginOrPadding: {
            margin: '0px',
            padding: '0px'
        },
        title: {
            color: 'var(--complementary-color)'
        },
        blockButton: {
            width: '100%',
            color: 'black'
        },
        inputText: {
            backgroundColor: 'var(--background-color-darker)',
            color: 'var(--complementary-color)',
            width: '100%',
            height: '50px'
        },
        dropdown: {
            width: '100%'
        }
    };

    const General = () => {
        const [allowDuplicateTracks, setAllowDuplicateTracks] = useState(userSettings.spotify.allowDuplicateTracks || false);
        const [analysis, setAnalysis] = useState(userSettings.spotify.analysis || false);
        const [nowPlayingAll, setNowPlayingAll] = useState(userSettings.spotify.nowPlayingAll || false);
        const [nowPlayingRequested, setNowPlayingRequested] = useState(userSettings.spotify.nowPlayingRequested || false);
        const [autoStart, setAutoStart] = useState(userSettings.spotify.autoStart || false);

        return (
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <h1 style={{...styles.noMarginOrPadding, ...styles.title}}>General Settings</h1>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={6}>
                    <InputSwitch id="duplicateRequestsSwitch" style={{top: '8px'}} checked={allowDuplicateTracks} onChange={(e) => {
                                setAllowDuplicateTracks(e.value);
                                update({spotify: {...userSettings.spotify, allowDuplicateTracks: e.value}});
                            }
                        } />
                    <label htmlFor="duplicateRequestsSwitch" style={{...styles.text, ...styles.noMarginOrPadding, marginLeft: '16px'}}>Allow duplicate tracks in queue</label>
                </Grid>
                <Grid item xs={6}>
                    <InputSwitch id="aiAnalysisSwitch" style={{top: '8px'}} checked={analysis} onChange={(e) => {
                                setAnalysis(e.value);
                                update({spotify: {...userSettings.spotify, analysis: e.value}});
                            }
                        } />
                    <label htmlFor="aiAnalysisSwitch" style={{...styles.text, ...styles.noMarginOrPadding, marginLeft: '16px'}}>Enable AI track analysis</label>
                </Grid>
                <Grid item xs={6}>
                    <InputSwitch id="nowPlayingAllSwitch" style={{top: '8px'}} checked={nowPlayingAll} onChange={(e) => {
                                setNowPlayingAll(e.value);
                                update({spotify: {...userSettings.spotify, nowPlayingAll: e.value}});
                            }
                        } />
                    <label htmlFor="nowPlayingAllSwitch" style={{...styles.text, ...styles.noMarginOrPadding, marginLeft: '16px'}}>Announce non-requested songs in chat</label>
                </Grid>
                <Grid item xs={6}>
                    <InputSwitch id="nowPlayingRequestedSwitch" style={{top: '8px'}} checked={nowPlayingRequested} onChange={(e) => {
                                setNowPlayingRequested(e.value);
                                update({spotify: {...userSettings.spotify, nowPlayingRequested: e.value}});
                            }
                        } />
                    <label htmlFor="nowPlayingRequestedSwitch" style={{...styles.text, ...styles.noMarginOrPadding, marginLeft: '16px'}}>Announce requested songs in chat</label>
                </Grid>
                <Grid item xs={6}>
                    <InputSwitch id="autoStartSwitch" style={{top: '8px'}} checked={autoStart} onChange={(e) => {
                                setAutoStart(e.value);
                                update({spotify: {...userSettings.spotify, autoStart: e.value}});
                            }
                        } />
                    <label htmlFor="autoStartSwitch" style={{...styles.text, ...styles.noMarginOrPadding, marginLeft: '16px'}}>Un-pause automatically on song request</label>
                </Grid>
            </Grid>
        );
    };
    const Responses = () => {
        const [responseMap, setResponseMap] = useState(userSettings?.spotify?.responseMap || {
            onSuccessfulRedeem: '',
            onBlockedRedeem: '',
            onSpotifyNotActive: '',
            onTrackPlay: '',
            onTrackPlayRequested: ''
        });
        const [shouldSave, setShouldSave] = useState(false);

        const suggestionsMap = {
            onSuccessfulRedeem: [
                "Request received! Enjoy ${song}!",
                "${song} has been added to the queue successfully!",
                "@${songrequester}, you've redeemed a song request!",
                "Get ready for ${song}! Thanks for the request, @${songrequester}.",
                "@${songrequester} requested ${song}, and it's coming right up!",
                "Enjoy the tunes, @${songrequester}!",
                "Adding your song request: ${song}. Rock on!"
            ],
            onBlockedRedeem: [
                "Sorry, @${songrequester}, your request is not allowed.",
                "This track can't be queued due to filter settings.",
                "Request declined due to filter policy.",
                "Uh-oh! That request doesn't meet the song requirements.",
                "This song doesn't pass the filter check.",
                "Request blocked! Not able to add ${song} to the playlist.",
                "Oops! This track can't be added due to policy restrictions."
            ],
            onSpotifyNotActive: [
                "Oops! No active Spotify device found.",
                "Please activate Spotify to process requests.",
                "Spotify is currently inactive.",
                "Can't find an active Spotify connection. Try reconnecting.",
                "Spotify isn't running. Please open it to play songs.",
                "Please check Spotify. It seems to be inactive.",
                "Spotify is not available right now. Please activate it."
            ],
            onTrackPlay: [
                "Now playing: ${song}!",
                "Enjoy this track: ${song}.",
                "Now spinning: ${song} on Spotify.",
                "Tune in to ${song} now!",
                "Here's a fresh track: ${song}.",
                "Current song: ${song}. Enjoy the vibe!",
                "${song} is now playing on Spotify. Enjoy!"
            ],
            onTrackPlayRequested: [
                "${song} is now playing, requested by @${songrequester}.",
                "Requested song on the air: ${song}.",
                "Enjoy the requested track: ${song}!",
                "@${songrequester} requested this one! Now playing: ${song}.",
                "Special request from @${songrequester}: ${song}. Enjoy!",
                "@${songrequester}'s pick: ${song} is on air now.",
                "Playing ${song}, requested by @${songrequester}!"
            ]
        };        

        const saveSettings = () => {
            update({spotify: {...userSettings.spotify, responseMap}});
        };

        const handleDropdownChange = (field, value) => {
            setResponseMap((prev) => ({...prev, [field]: value}));
            setShouldSave(true);
        };

        // Trigger save settings only when shouldSave is true
        useEffect(() => {
            if (shouldSave) {
                update({ spotify: { ...userSettings.spotify, responseMap } });
                setShouldSave(false); // Reset the flag after saving
            }
        }, [responseMap, shouldSave, update, userSettings.spotify]);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1 style={{...styles.noMarginOrPadding, ...styles.title}}>Chat Options</h1>
                </Grid>
                <Grid item xs={12}>
                    <p style={{...styles.noMarginOrPadding, ...styles.title}}>{"Set up Ratbot's chat messages for different Spotify events. Leave empty to use the default messages. Variable use: ${song} for \"Track by Artist\", ${songrequester} for the username of whom requested the song."}</p>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                
                {Object.keys(responseMap).map((field) => (
                    <Grid item xs={12} key={field}>
                        <label htmlFor={field} style={styles.text}>{`Message for ${field.replace(/([A-Z])/g, ' $1')}`}</label>
                        <Grid container alignItems="center">
                            {/* InputText for custom message editing */}
                            <Grid item xs={9}>
                                <InputText
                                    placeholder="Custom message"
                                    id={field}
                                    style={styles.inputText}
                                    value={responseMap[field]}
                                    onChange={(e) => setResponseMap((prev) => ({...prev, [field]: e.target.value}))}
                                    maxLength={500}
                                    onBlur={saveSettings}
                                />
                            </Grid>
                            {/* Dropdown for predefined suggestions */}
                            <Grid item xs={3}>
                                <Dropdown
                                    placeholder='Presets'
                                    id={`${field}-dropdown`}
                                    options={suggestionsMap[field].map((message) => ({ label: message, value: message }))}
                                    onChange={(e) => handleDropdownChange(field, e.value)}
                                    style={styles.dropdown}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <Box sx={styles.container}>
            <Grid container spacing={4}>
                <Grid item xs={5}>
                    <General />
                </Grid>
                <Grid item xs={1} sx={{height: '67.5vh'}}>
                    <Divider layout='vertical' />
                </Grid>
                <Grid item xs={6}>
                    <Responses />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChatGeneralSettings;