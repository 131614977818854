import React from 'react';
import DialogComponent from '../layouts/DialogComponent';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Grid, Tooltip, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import useSystemCommands from '../../utils/useSystemCommands';
import { useUserSettings } from '../../store/usersettings-context';

const SystemCommands = ({backgroundColor = '#5D3B9E'}) => {
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const [systemCommands, setSystemCommands] = useSystemCommands();

    const toggleHandler = (cmd, v) => {
        if (v === false) {
            update({ disabledCommands: [...userSettings.disabledCommands, cmd] });
        } else {
            update({ disabledCommands: userSettings.disabledCommands.filter(c => c !== cmd) });
        }
    };

    return (
        <DialogComponent footer='Edit, enable and disable built-in Ratbot commands' maxHeight='75vh'>
            <Grid container sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
                <Grid item xs={12}>
                    {Object.keys(systemCommands).map((cmdTitle, index) => (
                        <Accordion key={cmdTitle} sx={{ backgroundColor }}>
                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                                <Typography>{`${userSettings['commandDelimiter']}${systemCommands[cmdTitle]['command']} - ${cmdTitle}`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip placement='left' title={<Typography>{userSettings['disabledCommands'].includes(systemCommands[cmdTitle]['command']) ? `${cmdTitle} is disabled` : `${cmdTitle} is enabled`}</Typography>}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox size='medium' color='success' checked={!userSettings['disabledCommands'].includes(systemCommands[cmdTitle]['command'])}
                                            onChange={(e) => toggleHandler(systemCommands[cmdTitle]['command'], e.target.checked)} />}
                                            label={systemCommands[cmdTitle]['description']} labelPlacement='end' />
                                    </FormGroup>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
        </DialogComponent>
    );
};

export default SystemCommands;