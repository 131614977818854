import { Grid } from '@mui/material';
import { Slider } from 'primereact/slider';
import React, { useState } from 'react';

const TriviaSettings = ({openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = async () => {}, 
                            refresh = async () => {}, setMainViewHeader = () => {}}) => {
    const [roundSeconds, setRoundSeconds] = useState(parseInt(userSettings?.games?.trivia?.roundTimer / 1000, 10) || 30);

    const handleRoundSliderChange = (newVal) => {
        setRoundSeconds(newVal);
    };
    const handleRoundSliderRelease = (newVal) => {
        update({games: {...userSettings.games, trivia: {...userSettings.games.trivia, roundTimer: parseInt(newVal * 1000, 10)}}});
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <h2>Round Timer</h2>
                    <p>The amount of time viewers will have to submit their answers before results are tallied</p>
                    <label htmlFor='triviaRoundTimerSlider'>{`${roundSeconds} seconds`}</label>
                    <Slider id='triviaRoundTimerSlider' style={{maxWidth: '500px', marginTop: '16px'}} value={roundSeconds} min={10} max={120}
                        onChange={(e) => handleRoundSliderChange(e.value)} onSlideEnd={(e) => handleRoundSliderRelease(e.value)} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default TriviaSettings;