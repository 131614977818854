import { Card, CardActions, CardContent, FormControlLabel, Grid, Typography } from '@mui/material';
import React from 'react';
import useSystemCommands from '../../utils/useSystemCommands';
import { Checkbox } from 'primereact/checkbox';
import useIsMobile from '../../utils/useIsMobile';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import { useUserSettings } from '../../store/usersettings-context';

const SystemCommands = ({backgroundColor = '#5D3B9E'}) => {
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const [systemCommands, setSystemCommands] = useSystemCommands();
    const isMobile = useIsMobile();
    const isLarge = useWindowSizeThreshold(1280, true);
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const cardStyle = {
        height: '100%',
        background: `radial-gradient(circle, ${colorScheme.background75} 0%, ${colorScheme.background25} 100%)`,
        border: `2px solid ${colorScheme.background75}`
    };
    const hideScrollbar = {
        '&::-webkit-scrollbar': {
            display: 'none' // Hide vertical scrollbar
        },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
        'scrollbar-width': 'none' // For Firefox
    };

    const CommandCard = ({commandObj, title}) => {
        const checkHandler = (enable) => {
            if (enable) {
                update({disabledCommands: [...userSettings['disabledCommands'].filter((cmd) => cmd != commandObj['command'])]});
            } else {
                update({disabledCommands: [...userSettings['disabledCommands'], commandObj['command']]});
            }
        };

        return (
            <Card sx={cardStyle}>
                <CardContent>
                    <Typography variant='h4'>{title}</Typography>
                    <Typography variant='body1' sx={{marginTop: '8px', maxHeight: '75px', overflowY: 'auto'}}>{commandObj['description']}</Typography>
                    <Typography sx={{marginTop: '8px'}}>{`${userSettings['commandDelimiter']}${commandObj['command']}`}</Typography>
                    <Typography sx={{marginTop: '8px'}}>{`Admin command: ${commandObj['admin'] ? 'True' : 'False'}`}</Typography>
                    <CardActions>
                        <FormControlLabel sx={{marginTop: '8px', marginBottom: '8px'}} control={
                            <Checkbox onChange={(e) => checkHandler(e.checked)} style={{marginRight: '16px', marginLeft: '12px'}}
                                checked={!userSettings['disabledCommands'].includes(commandObj['command'])} />
                        } label='Enabled' labelPlacement='end' />
                    </CardActions>
                </CardContent>
            </Card>
        );
    };
    return (
        <Grid container spacing={2} sx={{...hideScrollbar, maxHeight: '80vh', overflowY: 'auto', padding: '16px', marginTop: '16px', paddingTop: '0px'}}>
            {Object.keys(systemCommands).map((title, index) => (
                <Grid item xs={(isMobile && isLarge) ? 12 : (!isMobile && isLarge) ? 6 : 3} key={`item-${index}`}>
                    <CommandCard key={`child-${index}`} commandObj={systemCommands[title]} title={title} />
                </Grid>
            ))}
        </Grid>
    );
};

export default SystemCommands;